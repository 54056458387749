/* eslint-disable camelcase */
import { Tooltip } from '@material-ui/core';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { FaCheck } from 'react-icons/fa';
import { IBatches } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (
  isIOS: boolean | undefined,
  data?: IBatches[],
): MUIDataTableColumnDef[] => {
  const iOSHeaders: MUIDataTableColumnDef[] = [
    { label: 'Amount', name: 'amount' },
    { label: 'Items', name: 'items' },
    { label: 'Delivery Distance', name: 'distance' },
    { label: 'Customers', name: 'customers' },
    {
      label: 'Warehouse',
      name: 'warehouse_location',
      options: {
        customBodyRender: (value, _tableMeta, _updateValue) => {
          if (value) {
            return (
              <Tooltip
                title={
                  <div>
                    <p>{value?.name}</p>
                    <p>{value?.street}</p>
                    {value?.city && <p>{value?.city?.completeName}</p>}
                    <p>{value?.zipCode}</p>
                  </div>
                }
                placement="bottom-end"
              >
                <p>{value?.name}</p>
              </Tooltip>
            );
          }
          return 'Not Found';
        },
      },
    },
    {
      label: 'Delivery Only',
      name: 'delivery_only',
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { delivery_only } = data[dataIndex];
            if (delivery_only) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Created At',
      name: 'created_at',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { created_at } = data[dataIndex];
            if (created_at) {
              return dateFormated(created_at);
            }
          }
          return '????';
        },
      },
    },
  ];
  const androidHeaders: MUIDataTableColumnDef[] = [
    { label: 'Amount', name: 'amount' },
    { label: 'Items', name: 'items' },
    { label: 'Delivery Distance', name: 'distance' },
    { label: 'Customers', name: 'customers' },
    {
      label: 'Warehouse',
      name: 'warehouse',
      options: {
        customBodyRender: (value, _tableMeta, _updateValue) => {
          if (value) {
            return (
              <Tooltip
                title={
                  <div>
                    <p>{value?.name}</p>
                    <p>{value?.address}</p>
                    {value?.city && <p>{value?.city?.completeName}</p>}
                  </div>
                }
                placement="bottom-end"
              >
                <p>{value?.name}</p>
              </Tooltip>
            );
          }
          return 'Not Found';
        },
      },
    },
    {
      label: 'Delivery Only',
      name: 'delivery_only',
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { delivery_only } = data[dataIndex];
            if (delivery_only) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Created At',
      name: 'created_at',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { created_at } = data[dataIndex];
            if (created_at) {
              return dateFormated(created_at);
            }
          }
          return '????';
        },
      },
    },
  ];
  return isIOS ? iOSHeaders : androidHeaders;
};
