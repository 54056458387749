/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Tooltip } from '@material-ui/core';
import React from 'react';

type Props = {
  pointCount: number;
  totalPoints: number;
  onClick: () => void;
  tooltipContent: NonNullable<React.ReactNode>;
};
const Marker: React.FC<Props> = ({
  pointCount,
  totalPoints,
  tooltipContent,
  onClick,
}: Props) => {
  return (
    <Tooltip title={tooltipContent} placement="bottom-end">
      <div
        style={{
          color: '#fff',
          background: '#cd0000',
          borderRadius: '50%',
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${10 + (pointCount / totalPoints) * 20}px`,
          height: `${10 + (pointCount / totalPoints) * 20}px`,
        }}
        onClick={onClick}
      >
        {pointCount}
      </div>
    </Tooltip>
  );
};

export default Marker;
