import { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import { colors } from '../../../../../styles/constants';
import { customRender } from './auxFunctions';

export default (data?: any[]): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'Amount',
      name: 'amount',
      options: {
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'amount', 'text'),
      },
    },
    {
      label: 'Items',
      name: 'items',
      options: {
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'items', 'text'),
      },
    },
    {
      label: 'Customers',
      name: 'customers',
      options: {
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'customers', 'text'),
      },
    },
    {
      label: 'Distance',
      name: 'distance',
      options: {
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'distance', 'decimal'),
      },
    },
    {
      label: 'Store Distance',
      name: 'storeDistance',
      options: {
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'storeDistance', 'decimal'),
      },
    },
    {
      label: 'Specific Stores',
      name: 'workStores',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const {
              workStores: { value, marked },
            } = data[dataIndex];
            const storesDiv = value.map((store: any) => <p>{store.value}</p>);
            if (value.length > 0) {
              return (
                <Tooltip title={storesDiv} placement="bottom-end">
                  <p style={marked ? { color: colors.primary } : {}}>
                    {value ? value.length : 0}
                  </p>
                </Tooltip>
              );
            }
            return (
              <p style={marked ? { color: colors.primary } : {}}>
                {value ? value.length : 0}
              </p>
            );
          }
          return 0;
        },
      },
    },
    {
      label: 'Anywhere',
      name: 'anywhere',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'anywhere', 'boolean'),
      },
    },
    {
      label: 'AnyStore',
      name: 'anyStore',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'anyStore', 'boolean'),
      },
    },
    {
      label: 'Delivery Only',
      name: 'deliveryOnly',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'deliveryOnly', 'boolean'),
      },
    },
    {
      label: 'Shop Only',
      name: 'shopOnly',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'shopOnly', 'boolean'),
      },
    },
    {
      label: 'No Heavyweight',
      name: 'noHeavyweight',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex =>
          customRender(data, dataIndex, 'noHeavyweight', 'boolean'),
      },
    },
  ];
  return headers;
};
