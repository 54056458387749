/* eslint-disable camelcase */
import { IconButton } from '@material-ui/core';
import { FirestoreCollection } from '@react-firebase/firestore';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  MdCancel,
  MdCheck,
  MdEdit,
  MdFilterList,
  MdLocationCity,
  MdMonetizationOn,
  MdShoppingCart,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Card from '../../components/Card';
import ContainerLoading from '../../components/ContainerLoading';
import Chart from '../../components/LineChart';
import Table from '../../components/MaterialTable';
import firebaseConfig from '../../config/firebaseConfig';
import { useRequest } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  ChartData,
  IBatches,
  IClientDetail,
  IClientDetailLicense,
  ICriterias,
  IWorkCity,
  IWorkStore,
} from '../../interfaces';
import { dateFormated } from '../../utils';
import { getClientStatus, getStatusObject } from '../../utils/clientStatus';
import ClientBatchList from './ClientBatchList';
import BatchesColumns from './TableComponents/batchesColumns';
import BatchesColumnsFirebase from './TableComponents/batchesColumnsFirebase';
import CityColumns from './TableComponents/cityColumns';
import CriteriaColumns from './TableComponents/criteriasColumns';
import LicenseActivationColumns from './TableComponents/licenseActivationColumns';
import StoreCloumns from './TableComponents/storeColumns';
import {
  BatchesContainer,
  CardContainer,
  CitiesContainer,
  Container,
  CriteriasContainer,
  InfoContainer,
  Input,
  InputContainer,
  LicensesContainer,
  StoresContainer,
} from './styles';

const Client: React.FC = () => {
  const { user } = useAuth();
  const { api } = useRequest();
  const { addToast } = useToast();
  const { id } = useParams<Record<string, string>>();
  const [client, setClient] = useState<IClientDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nameEdit, setNameEdit] = useState<boolean>(false);
  const [firebaseBatches, setFirebaseBatches] = useState<any>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  interface ReturnExpirationProps {
    label: string;
    background: string;
    color: string;
    description: string;
  }
  const [numberBatches, setNumberBatches] = useState<ChartData | null>();
  const [loadingNumberBatches, setLoadingNumberBatches] = useState(true);

  const [status, setStatus] = useState<ReturnExpirationProps>();

  useEffect(() => {
    async function loadClient() {
      const response = await api.get(`/admin/devices/${id}`);
      if (!response) {
        addToast({
          title: 'Error',
          description: "Unable to fetch client's information",
          type: 'error',
        });
        return;
      }
      const { device } = response.data;
      const batches = response.data.batches;
      const criterias = response?.data?.criterias as ICriterias[];
      const workStore = response?.data?.workStore.map((store: IWorkStore) => {
        let name;
        if (response?.data?.device?.isIOS) {
          if (store?.warehouse) {
            name = `${store.warehouse?.name}`;
          } else {
            name = `${store.warehouseLocation?.warehouse?.name}`;
          }
        } else {
          name = `${store.name} - All stores `;
        }
        let storeName;
        if (response?.data?.device?.isIOS) {
          if (!store.warehouseLocation?.name) {
            storeName = 'All stores';
          } else {
            storeName = store.warehouseLocation?.name;
          }
        }
        return {
          ...store,
          name,
          store: storeName,
          address:
            response?.data?.device?.isIOS && store.warehouseLocation?.street,
          city:
            response?.data?.device?.isIOS && store.warehouseLocation?.city.city,
          state:
            response?.data?.device?.isIOS &&
            store.warehouseLocation?.city.state,
          excluded: response?.data?.device?.workStoreExclude,
        };
      });
      interface IncomingWorkCity {
        createdAt: Date;
        city: {
          completeName: string;
          city: string;
          state: string;
        };
      }
      const workCity = response?.data?.workCity.map(
        (c: IncomingWorkCity): IWorkCity => {
          return {
            city: c.city.city,
            state: c.city.state,
            excluded: response?.data?.device?.workCityExclude,
            createdAt: c.createdAt,
          };
        },
      );
      const licenseActivations = response?.data
        ?.licenseActivations as IClientDetailLicense[];
      const clientStatus = getClientStatus(
        response?.data?.device?.expirationDate,
        response?.data?.device?.lastLicenseActivation,
      );
      setStatus(getStatusObject(clientStatus));
      setClient(() => ({
        device,
        status: clientStatus,
        criterias,
        workStore,
        workCity,
        licenseActivations,
        batches,
      }));
      setLoadingNumberBatches(true);
      const firebaseResponse = await firebaseConfig.db
        .collection('devices')
        .doc(device.udid)
        .collection('batchStats')
        .get();
      if (firebaseResponse.size > 0) {
        setNumberBatches(
          firebaseResponse?.docs.reduce<any>(
            (acc, curr) => {
              if (curr.id !== 'total') {
                const data = curr.data();
                return {
                  xaxis: [...acc.xaxis, curr.id],
                  total: [...acc.total, data.batchCount || 0],
                  accepted: [...acc.accepted, data.acceptedCount || 0],
                };
              }
              return acc;
            },
            {
              xaxis: [],
              total: [],
              accepted: [],
            },
          ),
        );
      }
      setLoadingNumberBatches(false);
      setLoading(false);
    }
    loadClient();
  }, [addToast, api, id]);

  const criteriasColumns = useMemo(() => {
    return CriteriaColumns(client?.criterias);
  }, [client?.criterias]);

  const storesColumns = useMemo(() => {
    return StoreCloumns(client?.device?.isIOS, client?.workStore);
  }, [client?.device?.isIOS, client?.workStore]);

  const cityColumns = useMemo(() => {
    return CityColumns(client?.workCity);
  }, [client?.workCity]);

  const batchesColumns = useMemo(() => {
    if (client?.device?.isIOS) {
      return BatchesColumnsFirebase(client?.device?.isIOS, firebaseBatches);
    }
    return BatchesColumns(client?.device?.isIOS, client?.batches);
  }, [firebaseBatches, client?.device?.isIOS]);

  const licenseActivationsColumns = useMemo(() => {
    return LicenseActivationColumns(user?.isAdmin, client?.licenseActivations);
  }, [client?.licenseActivations, user?.isAdmin]);

  const updateName = useCallback(
    async name => {
      const response = await api.put(`/admin/devices/${id}`, { name });
      if (response && response.data) {
        setNameEdit(false);
        setClient(
          prevState =>
            ({
              ...prevState,
              device: response.data,
            } as IClientDetail),
        );
      }
    },
    [api, id],
  );
  return (
    <>
      {loading ? (
        <ContainerLoading />
      ) : (
        <Container>
          <CardContainer>
            <Card
              background="#fdfdfd"
              title="CRITERIAS"
              label={client?.criterias.length}
            >
              <MdFilterList size={35} />
            </Card>
            <Card
              background="#fdfdfd"
              title="STORES"
              label={client?.workStore.length}
            >
              <MdShoppingCart size={35} />
            </Card>
            <Card
              background="#fdfdfd"
              title="CITIES"
              label={client?.workCity.length}
            >
              <MdLocationCity size={35} />
            </Card>
            <Card
              background="#fdfdfd"
              title="BATCHES"
              label={
                client?.device.isIOS
                  ? firebaseBatches
                    ? firebaseBatches.length
                    : '??'
                  : client?.batches
                  ? client?.batches.length
                  : '??'
              }
            >
              <MdMonetizationOn size={35} />
            </Card>
            {status && (
              <Card
                background={status.background as string}
                label={status.label as string}
              >
                <p style={{ marginTop: 'auto' }}>{status.description}</p>
              </Card>
            )}
          </CardContainer>
          <InfoContainer>
            {user?.isAdmin && (
              <h1>
                Reseller: <span>{client?.device.reseller?.name ?? 'None'}</span>
              </h1>
            )}
            <h1>
              <b>Client Information</b>
            </h1>
            <span>
              <b>ID: </b> {client?.device.internalId}
            </span>
            <span>
              <b>Device ID: </b> {client?.device.deviceId}
            </span>
            <span>
              <b>UDID: </b> {client?.device.udid}
            </span>
            <span>
              <b>Name: </b> {client?.device.clientName}
              {!nameEdit && (
                <IconButton onClick={() => setNameEdit(true)}>
                  <MdEdit size={16} />
                </IconButton>
              )}
              {nameEdit && (
                <InputContainer>
                  <Input ref={nameInput} name="name" />
                  <IconButton
                    onClick={() => {
                      if (
                        nameInput &&
                        nameInput.current &&
                        nameInput.current.value
                      ) {
                        updateName(nameInput.current.value);
                      }
                    }}
                  >
                    <MdCheck size={16} />
                  </IconButton>
                  <IconButton onClick={() => setNameEdit(false)}>
                    <MdCancel size={16} />
                  </IconButton>
                </InputContainer>
              )}
            </span>
            <span>
              <b>Phone: </b>
              {client?.device.phoneNumber}
            </span>
            <span>
              <b>Email: </b>
              {client?.device.email}
            </span>
            <span>
              <b>Version: </b>
              {client?.device.versionName}
            </span>
            <span>
              <b>O.S: </b>
              {client?.device.isIOS ? 'IOS' : 'ANDROID'}
            </span>
            <span>
              <b>Instacart Version: </b>
              {client?.device.instacartVersion}
            </span>
            <span>
              <b>Manufacturer: </b>
              {client?.device.manufacturer}
            </span>
            <span>
              <b>Model: </b>
              {client?.device.model}
            </span>
            <span>
              <b>Activation Date: </b>
              {dateFormated(client?.device.activationDate as Date)}
            </span>
            <span>
              <b>Expiration Date: </b>
              {dateFormated(client?.device.expirationDate as Date)}
            </span>
          </InfoContainer>
          <CriteriasContainer>
            <Table
              title="Criterias"
              columns={criteriasColumns}
              data={client?.criterias as ICriterias[]}
              options={{
                fixedHeader: true,
                filter: 'false',
                selectableRows: 'none',
                search: 'false',
                downloadOptions: {
                  filename: 'criterias.csv',
                },
                responsive: 'standard',
              }}
            />
          </CriteriasContainer>
          <BatchesContainer>
            {client?.device.isIOS ? (
              <>
                <Chart
                  loading={loadingNumberBatches}
                  title="Batches"
                  xaxis={numberBatches?.xaxis as string[]}
                  series={[
                    {
                      name: 'Accepted',
                      data: numberBatches?.accepted as number[],
                    },
                    {
                      name: 'Total',
                      data: numberBatches?.total as number[],
                    },
                  ]}
                />
                <ClientBatchList udid={client?.device.udid} />
                <br />
                <FirestoreCollection
                  path={`/devices/${client?.device.udid}/acceptedBatches`}
                  limit={20}
                  orderBy={[{ field: 'acceptedAt', type: 'desc' }]}
                >
                  {d => {
                    if (!d.isLoading && d.value.length > 0) {
                      setFirebaseBatches(d.value);
                    }
                    return d.isLoading ? (
                      'Loading'
                    ) : (
                      <Table
                        title="Accepted Batches"
                        columns={batchesColumns}
                        data={d.value as IBatches[]}
                        options={{
                          filterType: 'dropdown',
                          filter: 'false',
                          search: 'false',
                          selectableRows: 'none',
                          fixedHeader: true,
                          responsive: 'standard',
                        }}
                      />
                    );
                  }}
                </FirestoreCollection>
              </>
            ) : (
              <Table
                title="Batches"
                columns={batchesColumns}
                data={client?.batches as IBatches[]}
                options={{
                  filterType: 'dropdown',
                  filter: 'false',
                  search: 'false',
                  selectableRows: 'none',
                  fixedHeader: true,
                  responsive: 'standard',
                }}
              />
            )}
          </BatchesContainer>
          <StoresContainer>
            <p style={{ margin: 10, fontSize: 26 }}>
              Stores -
              <span style={{ fontSize: 26, color: '#d43200', marginLeft: 10 }}>
                {client?.device?.workStoreExclude ? 'Ban' : 'Only Take'}
              </span>
            </p>
            <Table
              title="Stores"
              columns={storesColumns}
              data={client?.workStore as IWorkStore[]}
              options={{
                fixedHeader: true,
                selectableRows: 'none',
                downloadOptions: {
                  filename: 'stores.csv',
                },
                responsive: 'standard',
              }}
            />
          </StoresContainer>
          <CitiesContainer>
            <p style={{ margin: 10, fontSize: 26 }}>
              Cities -
              <span style={{ fontSize: 26, color: '#d43200', marginLeft: 10 }}>
                {client?.device?.workCityExclude ? 'Ban' : 'Only Take'}
              </span>
            </p>
            <Table
              title="Cities"
              columns={cityColumns}
              data={client?.workCity as IWorkCity[]}
              options={{
                fixedHeader: true,
                selectableRows: 'none',
                downloadOptions: {
                  filename: 'cities.csv',
                },
                responsive: 'standard',
              }}
            />
          </CitiesContainer>

          <LicensesContainer>
            <Table
              title="Licenses"
              columns={licenseActivationsColumns}
              data={client?.licenseActivations as IClientDetailLicense[]}
              options={{
                enableNestedDataAccess: '.',
                filterType: 'dropdown',
                filter: 'false',
                selectableRows: 'none',
                fixedHeader: true,
                responsive: 'standard',
              }}
            />
          </LicensesContainer>
        </Container>
      )}
    </>
  );
};

export default Client;
