import {
  format,
  getWeek,
  eachWeekOfInterval,
  isAfter,
  startOfDay,
  isBefore,
  endOfDay,
  endOfWeek,
  eachMonthOfInterval,
  endOfMonth,
  differenceInDays,
  isSameDay,
  differenceInHours,
  isTomorrow,
  differenceInMinutes,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';

// eslint-disable-next-line import/no-duplicates
import locale from 'date-fns/locale/en-US';
import { ChartResponse, ChartData, IClient } from '../interfaces';

function moneyFormat(money?: number): string {
  if (!money) {
    return '';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(money);
}
function dateFormated(date: string | Date): string {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'MM/dd/yyyy HH:mm:ss');
}
function dateToChart(date: Date) {
  return format(new Date(date), 'yyyy-MM-dd', { locale });
}

function dateFormatedToMonth(date: Date): string {
  return format(new Date(date), 'yyyy-MM', { locale });
}

function mapperChartDaily(response: ChartResponse[]) {
  const days: string[] = response.map((r: ChartResponse) =>
    dateToChart(r.created_at),
  );

  const androidDaily: number[] = days.map(
    d =>
      response.find(r => String(dateToChart(r.created_at)) === d)
        ?.active_users_android || 0,
  );
  const iosDaily: number[] = days.map(
    d =>
      response.find(r => String(dateToChart(r.created_at)) === d)
        ?.active_users_ios || 0,
  );
  const generalTotalDaily: number[] = days.map(
    d =>
      response.find(r => String(dateToChart(r.created_at)) === d)
        ?.active_users_total || 0,
  );

  const data: ChartData = {
    xaxis: days,
    android: androidDaily,
    ios: iosDaily,
    total: generalTotalDaily,
  };
  return data;
}

function mapperChartWeekly(response: ChartResponse[], start: Date, end: Date) {
  const initialDayEachWeek = eachWeekOfInterval({
    start,
    end,
  });

  const androidWeekly = initialDayEachWeek.map(i => {
    const totalAndroidWeekly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfWeek(endOfDay(i))),
    );

    function getTotalAndroid(total: number, item: any) {
      return total + item.active_users_android;
    }
    const soma = totalAndroidWeekly.reduce(getTotalAndroid, 0);
    return soma;
  });
  const iosWeekly = initialDayEachWeek.map(i => {
    const totalIOSWeekly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfWeek(endOfDay(i))),
    );
    function getTotalIOS(total: any, item: any) {
      return total + item.active_users_ios;
    }
    const soma = totalIOSWeekly.reduce(getTotalIOS, 0);
    return soma;
  });

  const totalWeekly = initialDayEachWeek.map(i => {
    const generalTotalWeekly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfWeek(endOfDay(i))),
    );
    function getGeneralTotal(total: any, item: any) {
      return total + item.active_users_total;
    }
    const soma = generalTotalWeekly.reduce(getGeneralTotal, 0);
    return soma;
  });
  const xaxis = initialDayEachWeek.map(i => `Semana ${getWeek(i)}`);
  const data: ChartData = {
    xaxis,
    android: androidWeekly,
    ios: iosWeekly,
    total: totalWeekly,
  };
  return data;
}
function mapperChartMonthly(response: ChartResponse[], start: Date, end: Date) {
  const initialDayEachMonth = eachMonthOfInterval({
    start,
    end,
  });

  const androidMonthly = initialDayEachMonth.map(i => {
    const totalAndroidMonthly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfMonth(endOfDay(i))),
    );
    function getTotalAndroidMonth(total: any, item: any) {
      return total + item.active_users_android;
    }
    const soma = totalAndroidMonthly.reduce(getTotalAndroidMonth, 0);
    return soma;
  });
  const iosMonthly = initialDayEachMonth.map(i => {
    const totalIosMontly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfMonth(endOfDay(i))),
    );
    function getTotalIosMonth(total: any, item: any) {
      return total + item.active_users_ios;
    }
    const soma = totalIosMontly.reduce(getTotalIosMonth, 0);
    return soma;
  });

  const totalMonthly = initialDayEachMonth.map(i => {
    const generalTotalMonthly = response.filter(
      d =>
        isAfter(startOfDay(d.created_at), i) &&
        isBefore(endOfDay(d.created_at), endOfMonth(endOfDay(i))),
    );
    function getGeneralTotalMonthly(total: any, item: any) {
      return total + item.active_users_total;
    }
    const soma = generalTotalMonthly.reduce(getGeneralTotalMonthly, 0);
    return soma;
  });
  const xaxis = initialDayEachMonth.map(i => dateFormatedToMonth(i));
  const data: ChartData = {
    xaxis,
    android: androidMonthly,
    ios: iosMonthly,
    total: totalMonthly,
  };
  return data;
}
function mapperChartData(response: ChartResponse[], start?: Date, end?: Date) {
  if (start && end) {
    const weeks = eachWeekOfInterval({
      start,
      end,
    });

    const months = eachMonthOfInterval({
      start,
      end,
    });
    if (weeks.length > 2 && months.length <= 2) {
      return mapperChartWeekly(response, start, end);
    }

    if (months.length > 2) {
      return mapperChartMonthly(response, start, end);
    }
  }
  return mapperChartDaily(response);
}

interface ReturnExpirationProps {
  label: string;
  background: string;
  color: string;
  description: string;
  date: string | Date;
}
interface ExpirationRequest {
  expirationDate: Date;
  lastLicenseActivation?: {
    licenseEarningsAmount: number;
    expiredAt?: Date;
    reachedEarningsDate?: Date;
    expiration: Date;
  };
}

function oldReturnExpirationDateObjectModified(
  date: string | Date,
): ReturnExpirationProps {
  let diffInDays = differenceInDays(new Date(date), new Date());
  let diffInHours = differenceInHours(new Date(date), new Date());
  const diffInMinutes = differenceInMinutes(new Date(date), new Date());
  if (isBefore(new Date(date), new Date())) {
    diffInDays = differenceInDays(new Date(), new Date(date));
    diffInHours = differenceInHours(new Date(), new Date(date));
    return {
      date,
      label: 'Expired',
      background: '#c53030',
      color: '#fff',
      description: `Expired ${
        diffInDays > 0 ? `${diffInDays} days` : `${diffInHours} hours`
      } ago`,
    };
  }
  if (isSameDay(new Date(date), new Date())) {
    return {
      date,
      label: 'Expires Today',
      background: '#eeeb41',
      color: '#000',
      description: `Expires in ${
        diffInHours > 0 ? `${diffInHours} hours` : `${diffInMinutes} min`
      }`,
    };
  }
  if (isTomorrow(new Date(date))) {
    return {
      date,
      label: 'Expires Tomorrow',
      background: '#3172b7',
      color: '#fff',
      description: `Expires in ${
        diffInHours > 0 ? `${diffInHours} hours` : `${diffInMinutes} min`
      }`,
    };
  }
  return {
    date,
    label: 'Active',
    background: '#009933',
    color: '#fff',
    description: `Expires in ${
      // eslint-disable-next-line no-nested-ternary
      diffInDays > 0
        ? `${diffInDays} days`
        : diffInHours > 0
        ? `${diffInHours} hours`
        : `${diffInMinutes} min`
    }`,
  };
}

function returnExpirationDateObjectModified(
  data: ExpirationRequest,
): ReturnExpirationProps {
  if (!data.lastLicenseActivation) {
    return oldReturnExpirationDateObjectModified(data.expirationDate);
  }
  const isExpired = !!data.lastLicenseActivation.expiredAt;
  const reachedEarnings = !!data.lastLicenseActivation.reachedEarningsDate;

  if (isExpired && data.lastLicenseActivation.expiredAt) {
    const { expiredAt } = data.lastLicenseActivation;
    let diffInDays = differenceInDays(expiredAt, new Date());
    let diffInHours = differenceInHours(expiredAt, new Date());
    diffInDays = differenceInDays(new Date(), expiredAt);
    diffInHours = differenceInHours(new Date(), expiredAt);
    return {
      date: expiredAt,
      label: 'Expired',
      background: '#c53030',
      color: '#fff',
      description: `Expired ${
        diffInDays > 0 ? `${diffInDays} days` : `${diffInHours} hours`
      } ago`,
    };
  }
  if (reachedEarnings && data.lastLicenseActivation.reachedEarningsDate) {
    return oldReturnExpirationDateObjectModified(
      data.lastLicenseActivation.expiration,
    );
  }
  return {
    date: new Date(),
    label: 'Active',
    background: '#009933',
    color: '#fff',
    description: 'Expires when reaches earnings',
  };
}
export {
  dateFormated,
  moneyFormat,
  mapperChartData,
  returnExpirationDateObjectModified,
};
