import {
  differenceInDays,
  isAfter,
  isBefore,
  isSameDay,
  isTomorrow,
} from 'date-fns';

type LastLicenseActivation = {
  licenseEarningsAmount: number;
  expiredAt?: Date;
  reachedEarningsDate?: Date;
  expiration: Date;
};

// eslint-disable-next-line no-shadow
export enum Status {
  ACTIVE_EARNINGS = 'Active Earnings',
  EXPIRED = 'Expired',
  EXPIRED_7 = 'Expired +7',
  EXPIRED_3 = 'Expired +3',
  EXPIRES_TODAY = 'Expires Today',
  EXPIRES_TOMORROW = 'Expires Tomorrow',
  ACTIVE = 'Active',
}
export function getStatusByDate(date: Date): Status {
  if (isBefore(new Date(date), new Date())) {
    if (differenceInDays(new Date(), new Date(date)) >= 7) {
      return Status.EXPIRED_7;
    }
    if (differenceInDays(new Date(), new Date(date)) >= 3) {
      return Status.EXPIRED_3;
    }
    return Status.EXPIRED;
  }
  if (isSameDay(new Date(date), new Date())) {
    return Status.EXPIRES_TODAY;
  }
  if (isTomorrow(new Date(date))) {
    return Status.EXPIRES_TOMORROW;
  }
  return Status.ACTIVE;
}

export function getClientStatus(
  expirationDate: Date,
  lastLicenseActivation?: LastLicenseActivation,
): Status {
  if (!lastLicenseActivation) {
    return getStatusByDate(expirationDate);
  }
  const isExpired = !!lastLicenseActivation.expiredAt;
  const reachedEarnings = !!lastLicenseActivation.reachedEarningsDate;

  if (isExpired && lastLicenseActivation.expiredAt) {
    return getStatusByDate(lastLicenseActivation.expiredAt);
  }
  if (reachedEarnings) {
    return getStatusByDate(expirationDate);
  }
  const statusByDate = getStatusByDate(expirationDate);
  if (differenceInDays(new Date(), new Date(expirationDate)) >= 15) {
    return Status.EXPIRED;
  }
  if (
    statusByDate === Status.EXPIRED ||
    statusByDate === Status.EXPIRED_7 ||
    statusByDate === Status.EXPIRED_3
  ) {
    return Status.ACTIVE_EARNINGS;
  }
  return Status.ACTIVE;
}

export function getStatusObject(
  status: Status,
): {
  label: string;
  background: string;
  color: string;
  description: string;
} {
  switch (status) {
    case Status.EXPIRED:
      return {
        label: 'Expired',
        background: '#c53030',
        color: '#fff',
        description: 'Expired',
      };
    case Status.EXPIRED_7:
      return {
        label: 'Expired +7',
        background: '#c53030',
        color: '#fff',
        description: 'Expired +7',
      };
    case Status.EXPIRED_3:
      return {
        label: 'Expired +3',
        background: '#c53030',
        color: '#fff',
        description: 'Expired +3',
      };
    case Status.EXPIRES_TODAY:
      return {
        label: 'Expires Today',
        background: '#eeeb41',
        color: '#fff',
        description: 'Expires Today',
      };
    case Status.EXPIRES_TOMORROW:
      return {
        label: 'Expires Tomorrow',
        background: '#3172b7',
        color: '#fff',
        description: 'Expires Tomorrow',
      };
    case Status.ACTIVE_EARNINGS:
      return {
        label: 'Active Earnings',
        background: '#009933',
        color: '#fff',
        description: 'Active Earnings',
      };
    case Status.ACTIVE:
      return {
        label: 'Active',
        background: '#009933',
        color: '#fff',
        description: 'Active',
      };
    default: {
      return {
        label: '????',
        background: '#000',
        color: '#fff',
        description: 'Unknown',
      };
    }
  }
}
