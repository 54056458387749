import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const Container = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;
`;
export const Button = styled.button`
  flex-direction: row;
  height: 120px;
  min-width: 150px;
  flex: 1;
  margin: 5px;
  border-radius: 4px;
  border-width: 0px;
  background: #fdfdfd;
  border-color: ${colors.borders};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
`;
