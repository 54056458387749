import { NavLink as Link } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { IconButton, Tooltip } from '@material-ui/core';
import { FiCopy, FiSend } from 'react-icons/fi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PropsLicenseRow } from '../../../interfaces';
import { ButtonCopy, ContainerKey } from '../styles';

export default (
  data: PropsLicenseRow[],
  toggleSent: (key: string) => void,
  isAdmin?: boolean,
): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      name: 'Toggle Sent',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: dataIndex => {
          const { keyId } = data[dataIndex];
          return (
            <IconButton color="inherit" onClick={() => toggleSent(keyId)}>
              <FiSend />
            </IconButton>
          );
        },
      },
    },
    {
      label: 'Key',
      name: 'keyId',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          const { keyId } = data[dataIndex];
          if (keyId) {
            return (
              <ContainerKey>
                <span>{keyId}</span>
                <Tooltip title="Copy Key" placement="right">
                  <CopyToClipboard text={keyId}>
                    <ButtonCopy>
                      <FiCopy size={20} />
                    </ButtonCopy>
                  </CopyToClipboard>
                </Tooltip>
              </ContainerKey>
            );
          }
          return 'Error';
        },
      },
    },
    {
      label: 'Used',
      name: 'used',
      options: {
        filter: true,
        filterList: ['Unused'],
        filterType: 'multiselect',
      },
    },
    {
      label: 'Sent',
      name: 'sent',
      options: {
        filterList: ['Not Sent'],
        filterType: 'multiselect',
        filter: true,
      },
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      label: 'App',
      name: 'app',
    },
    {
      label: 'Client',
      name: 'client',
      options: {
        filter: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customBodyRender: (value, _tableMeta, _updateValue) => {
          if (value.id) {
            return (
              <Link to={`/clients/${value.id}`}>
                <p>{value.name}</p>
              </Link>
            );
          }
          return <p>{value.name}</p>;
        },
      },
    },
    {
      label: 'Activated on',
      name: 'activated',
      options: {
        filter: false,
      },
    },
  ];
  if (isAdmin) {
    headers.push({
      label: 'Reseller',
      name: 'reseller',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    });
  }
  return headers;
};
