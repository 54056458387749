import styled from 'styled-components';
import { colors } from '../../../styles/constants';

export const Container = styled.div`
  margin: 2px 0px;
`;
export const InputText = styled.input`
  min-width: 350px;
  max-width: 400px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${colors.borders};
  background: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding: 0 8px;
  outline: none;
  @media screen and (max-width: 1200px) {
    min-width: 350px;
    max-width: 400px;
    height: 40px;
    border-radius: 2px;
    border-width: 0px;
    border-color: #000;
    background: #fff;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    outline: none;
  }
  @media screen and (max-width: 800px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 450px) {
  }
  &:focus {
    border-color: ${colors.primary};
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
  }
`;
export const Error = styled.span`
  color: ${colors.error};
  font-size: 14px;
  align-self: flex-start;
  margin: 0px 0px 4px 0px;
`;
