import { NavLink as Link } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { ISales } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (data: ISales[]): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'Gross',
      name: 'gross',
      options: {
        filter: false,
      },
    },
    {
      label: 'Fees',
      name: 'fees',
      options: {
        filter: false,
      },
    },
    {
      label: 'Net',
      name: 'net',
      options: {
        filter: false,
      },
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      label: 'App',
      name: 'app',
      options: {
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { createdAt } = data[dataIndex];
            if (createdAt) {
              return dateFormated(createdAt);
            }
          }
          return '????';
        },
      },
    },
    {
      label: 'Reseller',
      name: 'licenses',
      options: {
        customBodyRender: licenses => {
          if (licenses && licenses.length > 0) {
            if (licenses[0].reseller) {
              return licenses[0].reseller.name;
            }
          }
          return 'Not Found';
        },
      },
    },
    {
      label: 'Bought by',
      name: 'user.name',
    },
  ];
  return headers;
};
