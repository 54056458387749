import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAPTAkYXgZa22mOiGVxOt8U984rZ5qMqms',
  authDomain: 'myinstabot-2020.firebaseapp.com',
  databaseURL: 'https://myinstabot-2020.firebaseio.com',
  projectId: 'myinstabot-2020',
  storageBucket: 'myinstabot-2020.appspot.com',
  messagingSenderId: '1052849224087',
  appId: '1:1052849224087:web:7b49c6ed609b6724faecd2',
  measurementId: 'G-SCM2R6F5Z1',
};

const firebaseApp = firebase.initializeApp(config);
const db = firebase.firestore();

export default { db, config };
