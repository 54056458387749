import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: ${colors.primary};
  button {
    min-width: 350px;
    max-width: 400px;
    margin: 5px 0px;
    height: 40px;
    border-radius: 5px;
    border-width: 0px;
    background: ${colors.backgroundSubmitButton};
    color: ${colors.textLight};
    font-size: 16px;
    font-weight: bold;
    @media screen and (max-width: 1200px) {
      margin: 5px 0px;
      min-width: 350px;
      max-width: 400px;
      height: 40px;
      border-radius: 5px;
      border-width: 0px;
      background: ${colors.backgroundSubmitButton};
      color: ${colors.textLight};
      font-size: 16px;
      font-weight: bold;
    }
    @media screen and (max-width: 800px) {
    }
    @media screen and (max-width: 600px) {
    }
    @media screen and (max-width: 450px) {
    }
  }
`;
