import React, { useRef, useCallback } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import FormContainer from '../../components/Form/Container';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';
import logo from '../../assets/logotest.png';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { loading, signIn, setLoading } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const handleSubmit: SubmitHandler<SignInFormData> = useCallback(
    async ({ email, password }: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Please type your e-mail')
            .email('Invalid e-mail format'),
          password: Yup.string().required('Please type your password'),
        });
        await schema.validate(
          { email, password },
          {
            abortEarly: false,
          },
        );
        await signIn({ email, password });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [signIn, setLoading],
  );
  return (
    <Container>
      <img src={logo} alt="logo" />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormContainer>
          <Input name="email" placeholder="Type your e-mail" />
          <Input
            name="password"
            placeholder="Type your password"
            type="password"
          />
          <button type="submit" style={{ background: '#000' }}>
            {loading ? <ClipLoader color="#fff" size={10} /> : 'SignIn'}
          </button>
        </FormContainer>
      </Form>
    </Container>
  );
};

export default SignIn;
