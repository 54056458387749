import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: #fff;
  padding: 10px 0px;
  & + div {
    margin-top: 20px;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
