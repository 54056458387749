import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import { useAuth } from '../hooks/auth';
import PasswordCreate from '../pages/PasswordCreate';

const Routes: React.FC = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route
        path="/"
        component={user && user.needPasswordUpdate ? PasswordCreate : SignIn}
      />
    </Switch>
  );
};

export default Routes;
