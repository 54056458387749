import React from 'react';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../hooks/auth';
import { colors } from '../styles/constants';
import AuthRoutes from './auth.routes';
import MainRoutes from './main.routes';

export default () => {
  const { user, loadingUser } = useAuth();
  if (loadingUser) {
    return (
      <div
        style={{
          backgroundColor: colors.primary,
          height: '100%',
          width: '100%',
        }}
      >
        <ClipLoader color="#fff" size={10} />
      </div>
    );
  }
  if (user && !user.needPasswordUpdate) {
    return <AuthRoutes />;
  }
  return <MainRoutes />;
};
