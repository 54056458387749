import { createGlobalStyle } from 'styled-components';
import { colors } from './constants';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  #root {
    margin: 0 auto;
  }

  button {
    cursor: pointer;

  }
`;
