import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FaArrowLeft, FaCheck, FaMinus } from 'react-icons/fa';
import { Button, IconButton } from '@material-ui/core';
import MaterialTable from '../../../../components/MaterialTable';
import { Column, Container, Item } from './styles';
import firebaseConfig from '../../../../config/firebaseConfig';
import criteriasColumns from './columns/criterias';
import storeColumns from './columns/store';
import citiesColumns from './columns/cities';
import ContainerLoading from '../../../../components/ContainerLoading';

// import { Container } from './styles';

const WhyMissed: React.FC = () => {
  const { batchId, udid } = useParams<Record<string, string>>();
  const [info, setInfo] = useState<any>(null);
  const history = useHistory();
  const [internalId, setInternalId] = useState<any>(null);
  useEffect(() => {
    if (!batchId || !udid) return;
    firebaseConfig.db
      .collection('devices')
      .doc(udid)
      .collection('batchList')
      .doc(batchId)
      .get()
      .then(doc => {
        if (doc.exists) {
          setInfo(doc.data());
          console.log(doc.data());
        }
      });
    firebaseConfig.db
      .collection('devices')
      .doc(udid)
      .get()
      .then(doc => {
        if (doc.exists) {
          if (doc.data() && doc.data()?.internalId) {
            setInternalId(doc.data()?.internalId);
          }
        }
      });
  }, [batchId, udid]);

  const criteriaColumnsMemo = useMemo(() => {
    if (info) return criteriasColumns(info.criterias);
    return [];
  }, [info]);

  const storeColumnsMemo = useMemo(() => {
    if (info) return storeColumns(info.workStores);
    return [];
  }, [info]);

  const citiesColumnsMemo = useMemo(() => {
    if (info) return citiesColumns(info.workCities);
    return [];
  }, [info]);

  const typeOfBatch = useMemo(() => {
    if (!info) return 'Loading';
    if (info.batch.deliveryOnly && info.batch.shopOnly) {
      return 'Delivery and Shop ??????';
    }
    if (info.batch.deliveryOnly) {
      return 'Delivery Only';
    }
    if (info.batch.shopOnly) {
      return 'Shop Only';
    }
    return 'Full Service';
  }, [info]);

  if (!info) {
    return <ContainerLoading />;
  }
  const warehouseLocations = info.batch.warehouseInfo.warehouseLocations || [
    info.batch.warehouseInfo.warehouseLocation,
  ];
  console.log(warehouseLocations);
  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <IconButton
          size="medium"
          onClick={() => {
            history.push(`/clients/${internalId}`);
          }}
        >
          <FaArrowLeft size={32} /> Return to client ID: {internalId}
        </IconButton>
      </div>
      <Container>
        <h1>Batch</h1>
        <Item>
          <h2>Amount:</h2> ${info.batch.amount}
        </Item>
        <Item>
          <h2>Items:</h2> {info.batch.items} items
        </Item>
        <Item>
          <h2>Customers:</h2> {info.batch.customers} customers
        </Item>
        <Item>
          <h2>Distance:</h2> {info.batch.distance.toFixed(2)} miles
        </Item>
        <Item>
          <h2>Store Distance:</h2> {info.batch.distanceToStore.toFixed(2)} miles
        </Item>
        <Item>
          <h2>Heavyweight:</h2>{' '}
          {info.batch.heavyWeight ? <FaCheck /> : <FaMinus />}
        </Item>
        <Item>
          <h2>Type Of Service: </h2> {typeOfBatch}
        </Item>
        {info.batch.multipleStores ? (
          <Item>
            <h2>Multiple Stores</h2>
          </Item>
        ) : (
          <>
            <Item>
              <h2>Warehouse Name: </h2>
              {warehouseLocations[0].name}
            </Item>
            <Item>
              <h2>Warehouse Address: </h2>
              {warehouseLocations[0].street}, {warehouseLocations[0].city},{' '}
              {warehouseLocations[0].state},
            </Item>
          </>
        )}
      </Container>
      <MaterialTable
        title="Criterias"
        columns={criteriaColumnsMemo}
        data={info.criterias}
        options={{
          fixedHeader: true,
          filter: 'false',
          selectableRows: 'none',
          search: 'false',
          responsive: 'standard',
        }}
      />
      <br />
      <MaterialTable
        title={`Stores - ${info.workStoreExclude ? 'BAN' : 'Only Take'}`}
        columns={storeColumnsMemo}
        data={info.workStores}
        options={{
          enableNestedDataAccess: '.',
          fixedHeader: true,
          filter: 'false',
          selectableRows: 'none',
          search: 'false',
          responsive: 'standard',
        }}
      />
      <br />
      <MaterialTable
        title={`Cities - ${info.workCityExclude ? 'BAN' : 'Only Take'}`}
        columns={citiesColumnsMemo}
        data={info.workCities}
        options={{
          fixedHeader: true,
          filter: 'false',
          selectableRows: 'none',
          search: 'false',
          responsive: 'standard',
        }}
      />
    </>
  );
};

export default WhyMissed;
