import React from 'react';
import { Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import Route from './Route';
import Dashboard from '../pages/Dashboard';
import Clients from '../pages/Clients';
import Client from '../pages/Client';
import Resellers from '../pages/Resellers';
import Reseller from '../pages/Reseller';
import Licenses from '../pages/Licenses';
import NewLicense from '../pages/NewLicense';
import Sales from '../pages/Sales';
import Navbar from '../components/Navbar';
import BatchesView from '../pages/BatchesView';
import BatchesMapPage from '../pages/BatchesMapPage';
import ClientsMapPage from '../pages/ClientsMapPage';
import WhyMissed from '../pages/Client/ClientBatchList/WhyMissed';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    overflow: 'hidden',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: '#f5f5f5',
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Routes: React.FC = () => {
  const classes = useStyles();

  return (
    <Switch>
      <div className={classes.root}>
        <CssBaseline />
        <Navbar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            <Container maxWidth="lg" className={classes.container}>
              <Route path="/" component={Dashboard} isPrivate exact />
              <Route path="/clients" component={Clients} isPrivate exact />
              {/* <Route
                path="/batchList/:udid"
                component={ClientBatchList}
                isPrivate
              /> */}
              <Route
                path="/clients/:udid/batchlist/:batchId"
                component={WhyMissed}
                isPrivate
              />
              <Route path="/clients/:id" component={Client} isPrivate exact />
              <Route path="/sales" component={Sales} isPrivate exact />
              <Route path="/map" component={ClientsMapPage} isPrivate exact />
              <Route path="/batches" component={BatchesView} isPrivate exact />
              <Route
                path="/batches/map"
                component={BatchesMapPage}
                isPrivate
                exact
              />
              <Route path="/licenses" component={Licenses} isPrivate exact />
              <Route path="/licenses/new" component={NewLicense} isPrivate />
              <Route path="/resellers" component={Resellers} isPrivate exact />
              <Route path="/resellers/:id" component={Reseller} isPrivate />
            </Container>
          </Switch>
        </main>
      </div>
    </Switch>
  );
};

export default Routes;
