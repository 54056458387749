import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ClipLoader } from 'react-spinners';
import Button from '@material-ui/core/Button';
import { CreateOrderRequestBody, PayPalButtons } from '@paypal/react-paypal-js';

import { useHistory } from 'react-router-dom';
import Input from '../../components/Form/Input';
import InputSelect from '../../components/Form/InputSelect';
import FormContainer from '../../components/Form/Container';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Container, ContainerResume } from './styles';
import CheckboxInput from '../../components/Form/Checkbox';
import { useRequest } from '../../hooks/api';
import { colors } from '../../styles/constants';
import { generateLicenses, getPrices, validateAndGenerate } from './services';

interface ResellerProps {
  id: string;
  value: string;
  label: string;
}
interface LicenseFormData {
  app: string;
  type: string;
  quantity: number;
  reseller: string;
}
interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

const NewLicense: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const checkboxOptions: CheckboxOption[] = [
    { id: 'ios', value: 'ios', label: 'IOS' },
    { id: 'android', value: 'android', label: 'Android' },
  ];
  const checkboxOptionsLicense: CheckboxOption[] = [
    // { id: 'test', label: 'Test', value: 'test' },
    // { id: 'return', label: 'Return', value: 'return' },
    // { id: 'promo', label: 'Promo', value: 'promo' },
    { id: 'week', label: 'Week', value: 'week' },
    { id: 'month', label: 'Month', value: 'month' },
    // { id: 'weekEarnings', label: 'Earnings', value: 'weekEarnings' },
  ];
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { api } = useRequest();
  const [resellers, setResellers] = useState<ResellerProps[] | []>([]);
  const [prices, setPrices] = useState<{
    quantity: number;
    unitPrice: number;
    total: number;
    type: string;
    app: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function loadResellers() {
      const response = await api.get('/admin/users');
      if (response && response.data) {
        setResellers(
          response.data.map((r: any) => ({
            id: String(r.id),
            value: String(r.id),
            label: r.name,
          })),
        );
      }
    }
    if (user?.isAdmin) loadResellers();
  }, [api, user?.isAdmin]);

  const confirm = useCallback(
    async (quantity, app, type, reseller) => {
      try {
        await generateLicenses(api, quantity, app, type, reseller);
        // TODO TEMPORARY FIX
        history.push('/licenses');
      } catch (err) {
        console.tron.log(err);
        setLoading(false);
      }
    },
    [api],
  );

  const handleSubmit = useCallback(
    async data => {
      console.tron.log('Chegou');
      if (data.quantity < 1) {
        addToast({
          title: 'Invalid Quantity',
          type: 'error',
        });
        return;
      }
      if (
        data.type === 'test' ||
        data.type === 'return' ||
        (user?.isAdmin && !!prices)
      ) {
        confirm(data.quantity, data.app, data.type, data.reseller);
      } else {
        setLoading(true);
        const pricesResponse = await getPrices(
          api,
          data.quantity,
          data.app,
          data.type,
          data.reseller,
        );
        setLoading(false);
        if (pricesResponse) {
          setPrices({
            quantity: pricesResponse.quantity,
            unitPrice: pricesResponse.unitPrice,
            total: pricesResponse.total,
            app: data.app,
            type: data.type,
          });
        }
      }
    },
    [addToast, api, confirm, user?.isAdmin, prices],
  );
  const onChange = useCallback(() => {
    setPrices(null);
    if (
      formRef.current?.getFieldValue('type') === 'promo' &&
      !Number.isNaN(Number(formRef.current?.getFieldValue('quantity'))) &&
      Number(formRef.current?.getFieldValue('quantity')) < 5
    ) {
      formRef.current?.setFieldValue('quantity', '5');
    }
  }, []);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormContainer>
          <Input
            type="number"
            name="quantity"
            onChange={onChange}
            placeholder="Quantity of licenses"
          />
          <CheckboxInput
            title="Select Mobile Platform"
            defaultChecked
            defaultValue="ios"
            name="app"
            onClick={onChange}
            options={checkboxOptions}
          />
          <CheckboxInput
            defaultChecked
            defaultValue="weekEarnings"
            title="License Type"
            name="type"
            onClick={onChange}
            options={checkboxOptionsLicense}
          />
          {user?.isAdmin && (
            <InputSelect
              placeholder="Reseller"
              name="reseller"
              onChange={onChange}
              options={resellers}
            />
          )}
          {loading && <ClipLoader color={colors.primary} size={20} />}
          {prices && !loading && (
            <ContainerResume>
              <h1>Licenses Price</h1>
              <h3>Unit: {prices?.quantity}</h3>
              <h3>Unit Price: ${prices?.unitPrice}</h3>
              <h3>Total: ${prices?.total}</h3>
              <br />
            </ContainerResume>
          )}
          {!prices && !loading && (
            <Button
              disabled={!!prices}
              variant="contained"
              color="secondary"
              onClick={() => formRef.current?.submitForm()}
            >
              Generate License
            </Button>
          )}
          {prices && !loading && user?.isAdmin && (
            <Button
              disabled={!prices}
              variant="contained"
              color="secondary"
              onClick={() => formRef.current?.submitForm()}
            >
              Confirm
            </Button>
          )}
          {prices && !user?.isAdmin && (
            <Button disabled variant="contained" color="secondary">
              PAY BY ZELLE OR CASHAPP
            </Button>
          )}
        </FormContainer>
      </Form>
    </Container>
  );
};

export default NewLicense;
