import { FaCheck, FaMinus } from 'react-icons/fa';
import { colors } from '../../../../../styles/constants';

export function customRender(
  data: any,
  dataIndex: number,
  name: string,
  type: 'text' | 'boolean' | 'decimal' | 'date',
): any {
  if (data) {
    const { value, marked } = data[dataIndex][name];
    if (!value) return <FaMinus />;
    switch (type) {
      case 'text': {
        return <p style={marked ? { color: colors.primary } : {}}>{value}</p>;
      }
      case 'decimal': {
        return (
          <p style={marked ? { color: colors.primary } : {}}>
            {value.toFixed(2)}
          </p>
        );
      }
      case 'boolean': {
        if (value === true) {
          return <FaCheck style={marked ? { color: colors.primary } : {}} />;
        }
        return <FaMinus style={marked ? { color: colors.primary } : {}} />;
      }
      default:
        return value;
    }
  }
  return <p>???</p>;
}

export default { customRender };
