import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const Container = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;
`;
export const ClientLicenseContainer = styled.div`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonNewLicense = styled.button`
  background: ${colors.primary};
  flex-direction: row;
  display: flex;
  padding: 10px 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  margin: 10px 0px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-left: 8px;
    font-weight: bold;
  }
`;

export const ButtonCopy = styled.button`
  min-width: 30px !important;
  margin-right: auto !important;
  width: 30px !important;
  margin-left: 10px;
`;

export const ContainerKey = styled.div`
  display: flex !important;
  min-width: 200px;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  span {
    flex: 1;
    margin-right: auto;
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
`;
