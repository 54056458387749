import { Tooltip } from '@material-ui/core';
import { MUIDataTableColumnDef } from 'mui-datatables';
import TableCell from '../../../components/TableCell';
import { IClient } from '../../../interfaces';
import {
  dateFormated,
  returnExpirationDateObjectModified,
} from '../../../utils';
import { getStatusObject, Status } from '../../../utils/clientStatus';

export default (
  data: IClient[],
  isAdmin?: boolean,
  resellerFilter?: string,
): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'id',
      name: 'internalId',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'UDID',
      name: 'udid',
      options: {
        customBodyRenderLite: dataIndex => {
          const { udid } = data[dataIndex];
          if (udid) {
            return (
              <Tooltip title={udid} placement="right">
                <p>{udid.slice(-5)}</p>
              </Tooltip>
            );
          }
          return '--';
        },
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Name',
      name: 'clientName',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: 'Expiration Date',
      name: 'expirationDate',
      options: {
        filter: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customBodyRender: (value, _tableMeta, _updateValue) =>
          dateFormated(value),
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customBodyRender: (value: Status, _tableMeta, _updateValue) => {
          const statusObj = getStatusObject(value);
          return (
            <TableCell
              label={statusObj?.label ?? 'Error'}
              background={statusObj?.background ?? '#FFFF'}
              color={statusObj?.color ?? '#000'}
            />
          );
        },
        filterOptions: {
          names: [
            'Active',
            'Active Earnings',
            'Expires Today',
            'Expires Tomorrow',
            'Expired',
            'Expired +7',
            'Expired +3',
          ],
          logic: (date: any, filters: any[], row) => {
            if (filters.length) {
              return !filters.includes(date);
            }
            return false;
          },
        },
      },
    },
    {
      label: 'App',
      name: 'manufacturer',
    },
    { label: 'App Version', name: 'versionName' },
    {
      label: 'Earnings',
      name: 'lastLicenseActivation.licenseEarningsAmount',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { lastLicenseActivation } = data[dataIndex];
            if (lastLicenseActivation) {
              return lastLicenseActivation.licenseEarningsAmount.toFixed(2);
            }
          }
          return 'N/A';
        },
      },
    },
  ];
  if (isAdmin) {
    if (resellerFilter) {
      headers.push({
        label: 'Reseller',
        name: 'reseller',
        options: {
          filter: true,
          filterList: [resellerFilter],
        },
      });
    } else {
      headers.push({
        label: 'Reseller',
        name: 'reseller',
        options: {
          filter: true,
        },
      });
    }
  }
  return headers;
};
