import React from 'react';
import { Container } from './styles';

interface Reseller {
  id: number;
  name: string;
  onClick?: () => void;
}
const ResellerComponent: React.FC<Reseller> = ({ id, name, onClick }) => (
  <Container key={id} onClick={onClick}>
    <p>{name}</p>
  </Container>
);
export default ResellerComponent;
