import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

type Props = {
  component?: React.Component | React.FunctionComponent;
  isPrivate?: boolean;
} & RouteProps;

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}: Props): React.ReactElement {
  const { user } = useAuth();
  if (!user && isPrivate) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
}
