import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 160px;
  min-width: 200px;
  max-height: 200px;
  align-items: center;
  justify-content: center;
  margin: 2px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  background: #ebf8ff;
  &:hover {
    cursor: pointer;
  }
  p {
    color: #000;
    font-size: 24px;
    font-weight: bold;
  }
`;
