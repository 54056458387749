import { MUIDataTableColumnDef } from 'mui-datatables';
import { FaMinus } from 'react-icons/fa';
import { IClientDetailLicense } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (
  isAdmin?: boolean,
  data?: IClientDetailLicense[],
): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'Key',
      name: 'license.key',
    },
    {
      label: 'Activation Date',
      name: 'createdAt',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { createdAt } = data[dataIndex];
            if (createdAt) {
              return dateFormated(createdAt);
            }
          }
          return '????';
        },
      },
    },
    {
      label: 'Expiration Date',
      name: 'expiration',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { expiration } = data[dataIndex];
            if (expiration) {
              return dateFormated(expiration);
            }
          }
          return '????';
        },
      },
    },
    {
      label: 'Earnings',
      name: 'licenseEarningsAmount',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const {
              licenseEarningsAmount,
              license: { earningsAmount },
            } = data[dataIndex];
            if (!earningsAmount) return 'N/A';
            if (licenseEarningsAmount) {
              return licenseEarningsAmount.toFixed(2);
            }
            return <FaMinus />;
          }
          return '????';
        },
      },
    },
    {
      label: 'Earnings Guaranteed',
      name: 'reachedEarningsDate',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const {
              reachedEarningsDate,
              license: { earningsAmount },
            } = data[dataIndex];
            if (!earningsAmount) return 'N/A';
            if (reachedEarningsDate) {
              return dateFormated(reachedEarningsDate);
            }
            return earningsAmount;
          }
          return '????';
        },
      },
    },
    {
      label: 'Expired At',
      name: 'expiredAt',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const {
              expiredAt,
              license: { earningsAmount },
            } = data[dataIndex];
            if (!earningsAmount) return 'N/A';
            if (expiredAt) {
              return dateFormated(expiredAt);
            }
            return <FaMinus />;
          }
          return '????';
        },
      },
    },
  ];
  if (isAdmin) {
    headers.push({
      label: 'Reseller',
      name: 'license.reseller.name',
    });
  }
  return headers;
};
