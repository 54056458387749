import React from 'react';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';

const MaterialTable: React.FC<MUIDataTableProps> = ({
  columns,
  data,
  title,
  components,
  innerRef,
  options,
  ...rest
}) => {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
      {...rest}
    />
  );
};
export default MaterialTable;
