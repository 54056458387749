import { MUIDataTableColumnDef } from 'mui-datatables';
import { FaCheck } from 'react-icons/fa';
import { Tooltip } from '@material-ui/core';
import { ICriterias } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (data?: ICriterias[]): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    { label: 'Amount', name: 'amount' },
    { label: 'Items', name: 'items' },
    { label: 'Customers', name: 'customers' },
    { label: 'Distance', name: 'distance' },
    { label: 'Store Distance', name: 'storeDistance' },
    {
      label: 'Specific Stores',
      name: 'workStores',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { workStores } = data[dataIndex];
            const storesDiv = workStores.map(store => {
              if (store.warehouse) {
                return (
                  <div>
                    <p>{store.warehouse.name} - All Stores</p>
                  </div>
                );
              }
              if (store.warehouseLocation) {
                const { city, zipCode } = store.warehouseLocation;
                let { name } = store.warehouseLocation;
                if (!name && store.warehouseLocation.warehouse) {
                  name = store.warehouseLocation.warehouse.name;
                }
                if (
                  store.warehouseLocation.warehouse &&
                  !name.startsWith(store.warehouseLocation.warehouse.name)
                ) {
                  name = `${store.warehouseLocation.warehouse.name} - ${store.warehouseLocation.street}`;
                }
                return (
                  <div>
                    <p>{name}</p>
                    <p>{city.completeName}</p>
                    <p>{zipCode}</p>
                    <br />
                  </div>
                );
              }
              return (
                <div>
                  <p>???</p>
                </div>
              );
            });
            if (workStores.length > 0) {
              return (
                <Tooltip title={storesDiv} placement="bottom-end">
                  <p>{workStores ? workStores.length : 0}</p>
                </Tooltip>
              );
            }
            return <p>{workStores ? workStores.length : 0}</p>;
          }
          return 0;
        },
      },
    },
    {
      label: 'Anywhere',
      name: 'anywhere',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { anywhere } = data[dataIndex];
            if (anywhere) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'AnyStore',
      name: 'anyStore',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { anyStore } = data[dataIndex];
            if (anyStore) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Delivery Only',
      name: 'deliveryOnly',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { deliveryOnly } = data[dataIndex];
            if (deliveryOnly) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Shop Only',
      name: 'shopOnly',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { shopOnly } = data[dataIndex];
            if (shopOnly) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'No Heavyweight',
      name: 'noHeavyweight',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { noHeavyweight } = data[dataIndex];
            if (noHeavyweight) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { createdAt } = data[dataIndex];
            if (createdAt) {
              return dateFormated(createdAt);
            }
          }
          return 'Error';
        },
      },
    },
    {
      label: 'Updated At',
      name: 'updatedAt',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { updatedAt } = data[dataIndex];
            if (updatedAt) {
              return dateFormated(updatedAt);
            }
          }
          return 'Error';
        },
      },
    },
  ];
  return headers;
};
