import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { useToast } from './toast';

interface User {
  name: string;
  email: string;
  isAdmin: boolean;
  needPasswordUpdate: boolean;
}
interface SignInCredentials {
  email: string;
  password: string;
}
interface ResponseData {
  token: string;
  name: string;
  email: string;
  needPasswordUpdate: boolean;
  isAdmin: boolean;
}
interface AuthContextData {
  user: User | null;
  loading: boolean;
  loadingUser: boolean;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut(): Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
interface PropsToken {
  role?: string;
  userId?: number;
  name?: string;
  iat: number;
  exp: number;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const { addToast } = useToast();
  const history = useHistory();
  async function signOut() {
    localStorage.clear();
    setUser(null);
    setToken(null);
  }

  useEffect(() => {
    setLoading(true);
    setLoadingUser(true);
    const storageUser = localStorage.getItem('@Dashboard:user');
    const storageToken = localStorage.getItem('@Dashboard:token');
    if (storageUser && storageToken) {
      const decodedToken = jwt.decode(storageToken);
      const { exp } = decodedToken as PropsToken;
      if (Date.now() >= exp * 1000) {
        signOut();
        setLoading(false);
        setLoadingUser(false);
        return;
      }
      setUser(JSON.parse(storageUser));
      setToken(storageToken);
    }
    setLoading(false);
    setLoadingUser(false);
  }, []);

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        setLoading(true);
        const response = await api.post('/admin/sessions', { email, password });

        const signInData = response?.data as ResponseData;
        const userLogged = {
          name: signInData?.name,
          email: signInData?.email,
          isAdmin: signInData?.isAdmin,
          needPasswordUpdate: signInData.needPasswordUpdate,
        };
        localStorage.setItem('@Dashboard:user', JSON.stringify(userLogged));
        localStorage.setItem('@Dashboard:token', signInData.token);
        setUser(userLogged);
        setToken(response?.data?.token);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response) {
          if (err.response.data) {
            addToast({
              type: 'error',
              title: 'Error',
              description: err.response.data.error,
            });
          }
          if (err.response.status === 401) {
            signOut();
          }
          if (err.response.status === 403) {
            history.goBack();
          }
        }
      }
    },
    [addToast],
  );

  const value = useMemo(
    () => ({
      token,
      user,
      loading,
      signIn,
      setLoading,
      loadingUser,
      signOut,
    }),
    [user, token, loading, loadingUser, signIn],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
