import { MUIDataTableColumnDef } from 'mui-datatables';
import { colors } from '../../../../../styles/constants';

export default (data?: any[]): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'City Name',
      name: 'value',
      options: {
        customBodyRender: value => {
          return <p style={{ color: colors.primary }}>{value}</p>;
        },
      },
    },
  ];
  return headers;
};
