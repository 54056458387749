import GoogleMapReact from 'google-map-react';
import { useEffect, useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';
import Marker from './Marker';

function MapComponent({points, mapProps}) {
  const mapRef = useRef();
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  }, [points]);
  useEffect(() => {
    setMarkers(clusters.map(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const {onClusterClick, onPointClick, clusterTooltip, pointTooltip} = cluster;
      const {
        cluster: isCluster,
        point_count: pointCount
      } = cluster.properties;
      if (isCluster) {
        return (
          <Marker
            key={`cluster-${cluster.id}`}
            lat={latitude}
            lng={longitude}
            onClick={onClusterClick}
            tooltipContent={clusterTooltip}
            pointCount={pointCount}
            totalPoints={points.length}
          />
        );
      }
      return (
        <Marker
          key={`nonCluster-${cluster.properties.id}`}
          id={cluster.properties.id}
          lat={latitude}
          lng={longitude}
          onClick={onPointClick}
          tooltipContent={pointTooltip}
          pointCount={1}
          totalPoints={points.length}
        />
      );
    }));
  },[clusters]);
  return (
  <div style={{ height: '80vh', width: '100%', flex: 1, backgroundColor: 'red'}}>
    {points.length > 0 && (<GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyC6XwbM5wPMUWYDpOmdL3OemM2HyOjl-wI' }}
      defaultCenter={mapProps.center}
      defaultZoom={mapProps.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([
          bounds.nw.lng,
          bounds.se.lat,
          bounds.se.lng,
          bounds.nw.lat
        ]);
      }}
    >
      {markers}
    </GoogleMapReact>)}
  </div>);
}
export default MapComponent;
