import React, { createContext, useContext, useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { useToast } from './toast';
import { useAuth } from './auth';
import api from '../services/api';

interface RequestContextData {
  api: AxiosInstance;
}

const RequestContext = createContext<RequestContextData>(
  {} as RequestContextData,
);

interface PropsToken {
  role?: string;
  userId?: number;
  name?: string;
  iat: number;
  exp: number;
}

export const RequestProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const history = useHistory();
  api.interceptors.request.use(
    async response => {
      if (!response.url?.endsWith('sessions')) {
        const token = localStorage.getItem('@Dashboard:token');
        response.headers.Authorization = `Bearer ${token}`;
        if (token) {
          const decodedToken = jwt.decode(token);
          const { exp } = decodedToken as PropsToken;
          if (exp) {
            if (Date.now() >= exp * 1000) {
              addToast({
                type: 'error',
                title: 'Error',
                description: 'Token has Expired',
              });
              signOut();
            }
          }
        }
      }

      return response;
    },
    err => {
      return Promise.reject(err);
    },
  );

  api.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      if (err.response) {
        if (err.response.data) {
          addToast({
            type: 'error',
            title: 'Error',
            description: err.response.data.error,
          });
        }
        if (err.response.status === 401) {
          signOut();
        }
        if (err.response.status === 403) {
          history.goBack();
        }
      }
    },
  );

  const value = useMemo(
    () => ({
      api,
    }),
    [api],
  );
  return (
    <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
  );
};

export function useRequest() {
  const context = useContext(RequestContext);
  if (!context) {
    throw new Error('useRequest must be used within an RequestProvider');
  }
  return context;
}
