import { AxiosInstance } from 'axios';

const getPrices = async (
  api: AxiosInstance,
  quantity: number,
  app: string,
  type: string,
  reseller: string,
): Promise<
  | {
      quantity: number;
      unitPrice: number;
      total: number;
    }
  | undefined
> => {
  try {
    const response = await api.get(
      `admin/licenses/price?type=${type}&app=${app}&quantity=${quantity}&resellerId=${reseller}`,
    );
    if (response.data) {
      return {
        quantity: response.data.quantity,
        unitPrice: response.data.unitPrice,
        total: response.data.total,
      };
    }
    return undefined;
  } catch (err) {
    console.tron.log('New License getPrice error', err);
    return Promise.reject(err);
  }
};
const validateAndGenerate = async (
  api: AxiosInstance,
  data: {
    quantity: number;
    app: string;
    type: string;
    orderID: string;
  },
): Promise<void> => {
  try {
    const response = await api.post('/admin/order', data);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
const generateLicenses = async (
  api: AxiosInstance,
  quantity: number,
  app: string,
  type: string,
  reseller: string,
): Promise<
  {
    id: number;
    key: string;
  }[]
> => {
  try {
    const response = await api.post('/admin/licenses', {
      quantity,
      app,
      type,
      resellerId: reseller,
    });
    return response.data;
  } catch (err) {
    console.tron.log(`Error to generate license`, err);
    return Promise.reject(err);
  }
};
export { getPrices, generateLicenses, validateAndGenerate };
