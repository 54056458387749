import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { FirestoreProvider } from '@react-firebase/firestore';
import firebase from 'firebase';
import GlobalStyle from './styles/global';
import Routes from './routes';
import AppProvider from './hooks';
import './config/Reactotron';
import firebaseConfig from './config/firebaseConfig';

const App: React.FC = () => (
  <PayPalScriptProvider
    options={{
      'client-id':
        'AaiV2iY-nt1DevP2s9N2yjumL793eFTsPW1L6NlL9jXQH-8840U1sNWxec1OILHWWdrNVUgdD3SJ9Cwm',
    }}
  >
    <FirestoreProvider {...firebaseConfig.config} firebase={firebase}>
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <GlobalStyle />
      </AppProvider>
    </FirestoreProvider>
  </PayPalScriptProvider>
);

export default App;
