import React, { useCallback, useEffect, useState } from 'react';
import { FcAndroidOs, FcCalendar } from 'react-icons/fc';
import { AiFillApple, AiFillCreditCard } from 'react-icons/ai';
import { TiGroupOutline } from 'react-icons/ti';
import { isAfter, startOfDay } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { Container, CardContainer } from './styles';
import Chart from '../../components/LineChart';
import { useAuth } from '../../hooks/auth';
import { mapperChartData, moneyFormat } from '../../utils';
import { ChartData, IClient } from '../../interfaces';
import DateRangeForm from '../../components/DateRangePicker';
import Card from '../../components/Card';
import { useRequest } from '../../hooks/api';
import firebaseConfig from '../../config/firebaseConfig';

const Dashboard: React.FC = () => {
  const { api } = useRequest();
  const { user } = useAuth();
  const [numberActive, setNumberActive] = useState<ChartData | null>();
  const [numberUser, setNumberUser] = useState<ChartData | null>();
  const [numberRevenue, setNumberRevenue] = useState<ChartData | null>();
  const [numberBatches, setNumberBatches] = useState<ChartData | null>();
  const [loadingNumberActive, setLoadingNumberActive] = useState(true);
  const [loadingNumberUser, setLoadingNumberUser] = useState(true);
  const [loadingNumberRevenue, setLoadingNumberRevenue] = useState(true);
  const [loadingNumberBatches, setLoadingNumberBatches] = useState(true);
  const [androidClients, setAndroidClients] = useState<number>(0);
  const [iosClients, setIosClients] = useState<number>(0);
  const [newClients, setNewClients] = useState<number>(0);
  const [expiringClients, setExpiringClients] = useState<number>(0);
  const [revenue, setRevenue] = useState<number>(0);

  const loadNumberOfUsersActive = useCallback(
    async (start?: Date, end?: Date) => {
      setLoadingNumberActive(true);
      const response = await api.get(
        `/admin/graphs?stat=active_users&from=${start}&to=${end}`,
      );
      if (response?.data) {
        const structuredData = mapperChartData(response.data, start, end);
        setNumberActive(structuredData as ChartData);
      }
      setLoadingNumberActive(false);
    },
    [api],
  );
  const loadNewUsers = useCallback(
    async (start?: Date, end?: Date) => {
      setLoadingNumberUser(true);
      const response = await api.get(
        `/admin/graphs?stat=new_users&from=${start}&to=${end}`,
      );
      if (response?.data) {
        setNumberUser({
          total: response.data.yAxis.total,
          android: response.data.yAxis.android,
          ios: response.data.yAxis.ios,
          xaxis: response.data.xAxis,
        });
      }
      setLoadingNumberUser(false);
    },
    [api],
  );
  const loadBatchesStats = useCallback(async (start?: Date, end?: Date) => {
    setLoadingNumberBatches(true);
    const response = await firebaseConfig.db
      .collection('adminBatchStats')
      .get();
    if (response.size > 0) {
      setNumberBatches(
        response?.docs.reduce<any>(
          (acc, curr) => {
            if (curr.id !== 'total') {
              const data = curr.data();
              return {
                xaxis: [...acc.xaxis, curr.id],
                total: [...acc.total, data.batchCount],
                accepted: [...acc.accepted, data.acceptedCount],
              };
            }
            return acc;
          },
          {
            xaxis: [],
            total: [],
            accepted: [],
          },
        ),
      );
    }
    setLoadingNumberBatches(false);
  }, []);
  const loadRevenue = useCallback(
    async (start?: Date, end?: Date) => {
      setLoadingNumberRevenue(true);
      const response = await api.get(
        `/admin/graphs?stat=revenue&from=${start}&to=${end}`,
      );
      if (response?.data) {
        setNumberRevenue({
          total: response.data.yAxis.total,
          android: response.data.yAxis.android,
          ios: response.data.yAxis.ios,
          xaxis: response.data.xAxis,
        });
      }
      const revenueStat = await api.get(`/admin/stats/revenue`);
      if (revenueStat?.data) {
        setRevenue(revenueStat.data.week);
      }
      setLoadingNumberRevenue(false);
    },
    [api],
  );

  const loadClients = useCallback(async () => {
    const statsResponse = await api.get('admin/stats/devices');
    if (statsResponse) {
      setAndroidClients(
        (statsResponse?.data?.active?.android +
          statsResponse?.data?.activeTesting?.android) as number,
      );
      setIosClients(
        (statsResponse?.data?.active?.ios +
          statsResponse?.data?.activeTesting?.ios) as number,
      );
      setExpiringClients(statsResponse?.data?.expiringSoon?.total as number);
      setNewClients(statsResponse?.data?.newUsersWeek.total as number);
    }
  }, [api]);

  useEffect(() => {
    loadNumberOfUsersActive();
    loadNewUsers();
    if (user?.isAdmin) {
      loadRevenue();
      loadBatchesStats();
    }
    loadClients();
  }, [
    loadBatchesStats,
    loadClients,
    loadNewUsers,
    loadNumberOfUsersActive,
    loadRevenue,
    user?.isAdmin,
  ]);

  return (
    <Container>
      <CardContainer>
        <Card
          background="#fdfdfd"
          title="Android Clients (active and testing)"
          label={androidClients}
        >
          <FcAndroidOs size={45} />
        </Card>

        <Card
          background="#fdfdfd"
          title="IOS Clients (active and testing)"
          label={iosClients}
        >
          <AiFillApple color="#000" size={45} />
        </Card>

        <Card
          background="#fdfdfd"
          title="New Clients (week)"
          label={newClients}
        >
          <TiGroupOutline size={45} />
        </Card>
        <Card
          background="#fdfdfd"
          title="Expiring in 24 hrs"
          label={expiringClients}
        >
          <FcCalendar size={45} />
        </Card>
        {user?.isAdmin && (
          <Card
            background="#fdfdfd"
            title="Revenue (week)"
            label={moneyFormat(revenue)}
          >
            <AiFillCreditCard color="#11de17" size={45} />
          </Card>
        )}
      </CardContainer>
      <Chart
        loading={loadingNumberActive}
        title="Number of Active Clients"
        xaxis={numberActive?.xaxis as string[]}
        series={[
          {
            name: 'Android',
            data: numberActive?.android as number[],
          },
          {
            name: 'IOS',
            data: numberActive?.ios as number[],
          },
          {
            name: 'Total',
            data: numberActive?.total as number[],
          },
        ]}
      >
        <DateRangeForm
          onClick={(start, end) => loadNumberOfUsersActive(start, end)}
        />
      </Chart>
      <Chart
        loading={loadingNumberUser}
        title="New Clients"
        xaxis={numberUser?.xaxis as string[]}
        series={[
          {
            name: 'Android',
            data: numberUser?.android as number[],
          },
          {
            name: 'IOS',
            data: numberUser?.ios as number[],
          },
          {
            name: 'Total',
            data: numberUser?.total as number[],
          },
        ]}
      >
        <DateRangeForm onClick={(start, end) => loadNewUsers(start, end)} />
      </Chart>
      {user?.isAdmin && (
        <Chart
          loading={loadingNumberRevenue}
          title="Revenue"
          xaxis={numberRevenue?.xaxis as string[]}
          series={[
            {
              name: 'Android',
              data: numberRevenue?.android as number[],
            },
            {
              name: 'IOS',
              data: numberRevenue?.ios as number[],
            },
            {
              name: 'Total',
              data: numberRevenue?.total as number[],
            },
          ]}
        >
          <DateRangeForm onClick={(start, end) => loadRevenue(start, end)} />
        </Chart>
      )}
      {user?.isAdmin && (
        <Chart
          loading={loadingNumberBatches}
          title="Batches (iOS Only)"
          xaxis={numberBatches?.xaxis as string[]}
          series={[
            {
              name: 'Accepted',
              data: numberBatches?.accepted as number[],
            },
            {
              name: 'Total',
              data: numberBatches?.total as number[],
            },
          ]}
        >
          <DateRangeForm onClick={(start, end) => loadRevenue(start, end)} />
        </Chart>
      )}
    </Container>
  );
};

export default Dashboard;
