import React, { useCallback, useEffect, useMemo, useState } from 'react';

import MaterialTable from '../../components/MaterialTable';
import { useAuth } from '../../hooks/auth';
import firebaseConfig from '../../config/firebaseConfig';
import BatchesViewColumns from './BatchesViewColumns';

async function fetchBatches({
  lastFetchedPage,
  page,
  batches,
}: {
  lastFetchedPage: number;
  page: number;
  batches: any[];
}) {
  const pageSize = 20;
  let response = firebaseConfig.db
    .collection('newBatches')
    .orderBy('lastSeen', 'desc');
  if (
    batches.length > 0 &&
    batches[batches.length - 1] !== null &&
    page > lastFetchedPage
  ) {
    console.log('NEXT PAGE');
    response = response.startAfter(batches[batches.length - 1]).limit(pageSize);
  } else if (
    page < lastFetchedPage &&
    batches.length > 0 &&
    batches[0] !== null
  ) {
    response = response.endBefore(batches[0]).limitToLast(pageSize);
    console.log('PREVIOUS PAGE');
  } else {
    response = response.limit(pageSize);
  }
  const b = await response.get();

  return b.docs;
}

const BatchesView: React.FC = () => {
  // const [docs, setDocs] = useState<any[]>([]);
  const [batches, setBatches] = useState<any[]>([]);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const columns = useMemo(() => {
    return BatchesViewColumns(batches);
  }, [batches]);

  useEffect(() => {
    fetchBatches({
      page: 0,
      lastFetchedPage,
      batches,
    }).then(docs => {
      setBatches(docs);
      setLoading(false);
      setLastFetchedPage(0);
    });
  }, []);

  // useEffect(() => {
  //   setBatches(docs.map(doc=> doc.data()));
  // }, [docs]);

  return (
    <MaterialTable
      columns={columns}
      data={batches.map(batch => batch.data())}
      title="Batches"
      options={{
        rowsPerPageOptions: [],
        search: false,
        filter: false,
        selectableRowsHeader: false,
        selectableRowsOnClick: false,
        isRowSelectable: () => false,
        fixedSelectColumn: false,
        sort: false,
        rowsPerPage: 20,
        selectableRows: 'none',
        fixedHeader: true,
        responsive: 'standard',
        serverSide: true,
        count: 10000,
        onTableChange: (action, tableState) => {
          if (action === 'changePage') {
            fetchBatches({
              page: tableState.page,
              lastFetchedPage,
              batches,
            }).then(docs => {
              setBatches(docs);
              setLoading(false);
              setLastFetchedPage(tableState.page);
            });
          }
        },
      }}
    />
  );
};

export default BatchesView;
