import { MUIDataTableColumnDef } from 'mui-datatables';
import { IWorkCity } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (data?: IWorkCity[]): MUIDataTableColumnDef[] => {
  const headers: MUIDataTableColumnDef[] = [
    {
      label: 'City',
      name: 'city',
    },
    {
      label: 'State',
      name: 'state',
    },
  ];
  return headers;
};
