import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core/';
import {
  FaBuromobelexperte,
  FaList,
  FaMapMarked,
  FaMapMarker,
} from 'react-icons/fa';
import { FiDollarSign, FiMap, FiUsers } from 'react-icons/fi';
import { TiGroupOutline } from 'react-icons/ti';
import { AiOutlineIdcard } from 'react-icons/ai';
import { NavLink as Link } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { BiCartAlt } from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export default function SideBar(): JSX.Element {
  const classes = useStyles();
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List>
      <ListItem
        button
        component={props => (
          <Link
            {...props}
            activeStyle={{
              color: '#152246',
            }}
            to="/"
          />
        )}
      >
        <ListItemIcon>
          <FaBuromobelexperte size={20} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem
        button
        component={props => (
          <Link
            {...props}
            activeStyle={{
              color: '#152246',
            }}
            to="/clients"
          />
        )}
      >
        <ListItemIcon>
          <TiGroupOutline size={20} />
        </ListItemIcon>
        <ListItemText primary="Clients" />
      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AiOutlineIdcard size={20} />
        </ListItemIcon>
        <ListItemText primary="Licenses" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/licenses"
              />
            )}
          >
            <ListItemIcon>
              <AiOutlineIdcard size={20} />
            </ListItemIcon>
            <ListItemText primary="List Licenses" className={classes.nested} />
          </ListItem>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/licenses/new"
              />
            )}
          >
            <ListItemIcon>
              <BiCartAlt size={20} />
            </ListItemIcon>
            <ListItemText primary="New License" className={classes.nested} />
          </ListItem>
        </List>
      </Collapse>

      {user?.isAdmin && (
        <>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/resellers"
              />
            )}
          >
            <ListItemIcon>
              <FiUsers size={20} />
            </ListItemIcon>
            <ListItemText primary="Resellers" />
          </ListItem>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/sales"
              />
            )}
          >
            <ListItemIcon>
              <FiDollarSign size={20} />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItem>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/map"
              />
            )}
          >
            <ListItemIcon>
              <FiMap size={20} />
            </ListItemIcon>
            <ListItemText primary="Map" />
          </ListItem>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/batches"
              />
            )}
          >
            <ListItemIcon>
              <FaList size={20} />
            </ListItemIcon>
            <ListItemText primary="Batches" />
          </ListItem>
          <ListItem
            button
            component={props => (
              <Link
                {...props}
                activeStyle={{
                  color: '#152246',
                }}
                to="/batches/map"
              />
            )}
          >
            <ListItemIcon>
              <FaMapMarked size={20} />
            </ListItemIcon>
            <ListItemText primary="Batches Map" />
          </ListItem>
        </>
      )}
    </List>
  );
}
