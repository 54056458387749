import { useCallback, useEffect, useRef, useState } from 'react';
import firebaseConfig from '../../config/firebaseConfig';
import { useAuth } from '../../hooks/auth';
import MapComponent from '../../components/MapComponent';import firebase from 'firebase';


function BatchesMapPage() {
  const props = {
    center: {lat: 37.8097343, lng:-98.5556199},
    zoom: 5
  };
  const { user } = useAuth();
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if(!user || !user.isAdmin) return;
    let response = firebaseConfig.db.collection('newBatches').limit(10000);
    response = response.where("lastSeen", ">", firebase.firestore.Timestamp.fromDate(new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7))));
    response = response.orderBy("lastSeen", "desc");
    updatePoints(response);
  }, [user]);

  const updatePoints = useCallback((response) => {
    response.get().then(snapshot => {
      const p = snapshot.docs.filter(item=>!!item.data().coords).map(item=> {
        const data = item.data();
        return {
        type: "Feature",
        properties: { cluster: false, id: item.id },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(data.coords.longitude),
            parseFloat(data.coords.latitude)
          ]
        }
      }
    });
    setPoints(p);
    setLoading(false);
    }).catch(err => {console.log(err)});
  });
  return (
  <div style={{ height: '80vh', width: '100%', flex: 1}}>
    <h1>Batches Map - Last 24 hours - Limit 10000</h1>
    {!loading && points.length > 0 && (<MapComponent mapProps={props} points={points}/>)}
  </div>);
}
export default BatchesMapPage;
