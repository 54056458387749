import React, { useRef, useEffect } from 'react';
import { OptionTypeBase, Props as CreatableProps } from 'react-select';
import Select from 'react-select/creatable';
import { useField } from '@unform/core';

interface Props extends CreatableProps<OptionTypeBase> {
  name: string;
}

export default function CreatableSelect({
  name,
  ...rest
}: Props): React.ReactElement {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    minWidth: 290,
  });

  return (
    <Select
      defaultValue={defaultValue}
      ref={selectRef}
      isClearable
      classNamePrefix="react-select"
      {...rest}
      styles={{ input: styles => ({ ...styles, ...dot() }) }}
    />
  );
}
