import styled from 'styled-components';

export const Container = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;
`;
export const ClientLicenseContainer = styled.div`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const SalesCard = styled.div`
  flex-direction: column;
  height: 120px;
  min-width: 150px;
  flex: 1;
  margin: 5px;
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;
export const SalesCardTitle = styled.span`
  align-self: flex-start;
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
export const SalesCardValue = styled.p`
  align-self: flex-end;
  font-size: 32px;
  font-weight: bold;

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;
