import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FcAndroidOs, FcCalendar } from 'react-icons/fc';
import { AiFillApple } from 'react-icons/ai';
import { TiGroupOutline } from 'react-icons/ti';

import { useHistory } from 'react-router-dom';
import Table from '../../components/MaterialTable';
import { IClient, StatsClient } from '../../interfaces';
import { useAuth } from '../../hooks/auth';
import ContainerLoading from '../../components/ContainerLoading';
import { dateFormated } from '../../utils';
import { Container, CardContainer, ContainerTitles } from './styles';
import Card from '../../components/Card';
import { useRequest } from '../../hooks/api';
import { useToast } from '../../hooks/toast';
import TableColumns from './TableComponents/columns';
import { getClientStatus } from '../../utils/clientStatus';

const Clients: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [stats, setStats] = useState<StatsClient>();
  const { user } = useAuth();
  const { api } = useRequest();
  const { addToast } = useToast();
  const history = useHistory();

  const [clients, setClients] = useState<IClient[] | []>([]);
  const columns = useMemo(() => {
    const resellerFilter =
      history?.location?.state?.resellerFilter ?? undefined;
    return TableColumns(clients, user?.isAdmin ?? undefined, resellerFilter);
  }, [clients, history?.location?.state?.resellerFilter, user?.isAdmin]);

  const loadData = useCallback(async () => {
    const response = await api.get('/admin/devices');
    if (response) {
      setClients(
        response.data.map((client: IClient) => {
          return {
            ...client,
            status: getClientStatus(
              client.expirationDate,
              client.lastLicenseActivation,
            ),
            manufacturer: client.manufacturer === 'Apple' ? 'iOS' : 'Android',
            activationDate: client.activationDate
              ? dateFormated(client.activationDate)
              : '',
            clientName: client.clientName ? client.clientName : '--',
            phoneNumber: client.phoneNumber ? client.phoneNumber : '--',
            reseller: client.reseller ? client.reseller.name : '--',
          };
        }),
      );
    } else {
      addToast({
        title: 'Error',
        description: 'Unable to fetch clients',
        type: 'error',
      });
    }
    const responseStats = await api.get('/admin/stats/devices');
    if (responseStats) {
      setStats(responseStats.data as StatsClient);
    } else {
      addToast({
        title: 'Error',
        description: 'Unable to fetch stats',
        type: 'error',
      });
    }
    setLoading(false);
  }, [addToast, api]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <Container>
      {loading ? (
        <ContainerLoading />
      ) : (
        <>
          <CardContainer>
            <Card
              background="#fdfdfd"
              title="Total Clients"
              label={stats?.totals.total as number}
            >
              <TiGroupOutline size={35} />
              <ContainerTitles>
                <p>Android</p>
                <span>{stats?.totals.android}</span>
              </ContainerTitles>
              <ContainerTitles>
                <p>IOS</p>
                <span>{stats?.totals.ios}</span>
              </ContainerTitles>
            </Card>
            <Card
              background="#fdfdfd"
              title="Android Clients (active)"
              label={stats?.active.android as number}
            >
              <FcAndroidOs size={35} />
            </Card>

            <Card
              background="#fdfdfd"
              title="IOS Clients (active)"
              label={stats?.active.ios as number}
            >
              <AiFillApple color="#000" size={35} />
            </Card>
            <Card
              background="#fdfdfd"
              title="Expiring in 24 hrs"
              label={stats?.expiringSoon.total as number}
            >
              <FcCalendar size={35} />
            </Card>
            <Card
              background="#fdfdfd"
              title="Testing"
              label={stats?.activeTesting.total as number}
            >
              <TiGroupOutline size={35} />
              <ContainerTitles>
                <p>Android</p>
                <span>{stats?.activeTesting.android}</span>
              </ContainerTitles>
              <ContainerTitles>
                <p>IOS</p>
                <span>{stats?.activeTesting.ios}</span>
              </ContainerTitles>
            </Card>
          </CardContainer>
          <Table
            title="Clients"
            columns={columns}
            data={clients}
            options={{
              filterType: 'multiselect',
              enableNestedDataAccess: '.',
              selectableRows: 'none',
              responsive: 'standard',
              onRowClick: (rowData: string[]) => {
                const internalId = rowData[0];
                if (internalId) {
                  const win = window.open(`/clients/${internalId}`, '_blank');
                  if (win) win.focus();
                }
              },
            }}
          />
        </>
      )}
    </Container>
  );
};

export default Clients;
