import { MUIDataTableColumnDef } from 'mui-datatables';
import { IWorkStore } from '../../../interfaces';
import { dateFormated } from '../../../utils';

export default (
  isIOS: boolean | undefined,
  data?: IWorkStore[],
): MUIDataTableColumnDef[] => {
  const iOSHeaders: MUIDataTableColumnDef[] = [
    { label: 'Name', name: 'name' },
    { label: 'Store', name: 'store', options: { filter: false } },
    { label: 'Address', name: 'address', options: { filter: false } },
    { label: 'City', name: 'city' },
    { label: 'State', name: 'state' },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { createdAt } = data[dataIndex];
            if (createdAt) {
              return dateFormated(createdAt);
            }
          }
          return 'Error';
        },
      },
    },
  ];
  const androidHeaders: MUIDataTableColumnDef[] = [
    { label: 'Name', name: 'name', options: { filter: false } },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { createdAt } = data[dataIndex];
            if (createdAt) {
              return dateFormated(createdAt);
            }
          }
          return 'Error';
        },
      },
    },
  ];
  return isIOS ? iOSHeaders : androidHeaders;
};
