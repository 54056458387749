/* eslint-disable camelcase */
import { Tooltip } from '@material-ui/core';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { FaCheck } from 'react-icons/fa';
import { dateFormated } from '../../../utils';

export default (data: any[]): MUIDataTableColumnDef[] => {
  return [
    { label: 'Amount', name: 'amount' },
    { label: 'Items', name: 'items' },
    {
      label: 'Delivery Distance',
      name: 'distance',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { distance } = data[dataIndex];
            if (distance) {
              return distance.toFixed(2);
            }
          }
          return '???';
        },
      },
    },
    {
      label: 'Store Distance',
      name: 'distanceToStore',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { distanceToStore } = data[dataIndex];
            if (distanceToStore) {
              return distanceToStore.toFixed(2);
            }
          }
          return '???';
        },
      },
    },
    { label: 'Customers', name: 'customers' },
    {
      label: 'Warehouse',
      name: 'warehouseInfo',
      options: {
        customBodyRender: (value, _tableMeta, _updateValue) => {
          console.log(value);
          if (value) {
            const warehouseLocations = value.warehouseLocations || [
              value.warehouseLocation,
            ];
            const isMultipleStores = warehouseLocations.length > 1;
            if (isMultipleStores) {
              return (
                <Tooltip
                  title={warehouseLocations.map((wl: any) => (
                    <div>
                      <p>{wl.warehouse.name}</p>
                      <p>{wl.name}</p>
                      <p>{wl.street}</p>
                      <p>
                        {wl.city}, {wl.state}
                      </p>
                      <p>{wl.zip_code}</p>
                    </div>
                  ))}
                  placement="bottom-end"
                >
                  <p>Multiple Stores</p>
                </Tooltip>
              );
            }
            return (
              <Tooltip
                title={
                  <div>
                    {warehouseLocations[0].warehouse && (
                      <p>{warehouseLocations[0].warehouse.name}</p>
                    )}
                    <p>{warehouseLocations[0].name}</p>
                    <p>{warehouseLocations[0].street}</p>
                    <p>
                      {warehouseLocations[0].city},{' '}
                      {warehouseLocations[0].state}
                    </p>
                    <p>{warehouseLocations[0].zip_code}</p>
                  </div>
                }
                placement="bottom-end"
              >
                <p>
                  {warehouseLocations[0].name} - {warehouseLocations[0].city}
                </p>
              </Tooltip>
            );
          }
          return 'Not Found';
        },
      },
    },
    {
      label: 'Delivery Only',
      name: 'deliveryOnly',
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { deliveryOnly } = data[dataIndex];
            if (deliveryOnly) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Shop Only',
      name: 'shopOnly',
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { shopOnly } = data[dataIndex];
            if (shopOnly) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Heavyorder',
      name: 'heavyWeight',
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { heavyweight } = data[dataIndex];
            if (heavyweight) {
              return <FaCheck />;
            }
          }
          return '-';
        },
      },
    },
    {
      label: 'Last Seen',
      name: 'lastSeen',
      options: {
        customBodyRenderLite: dataIndex => {
          if (data) {
            const { lastSeen } = data[dataIndex];
            if (lastSeen) {
              return dateFormated(lastSeen.toDate());
            }
          }
          return '????';
        },
      },
    },
  ];
};
