import React from 'react';
import { Container } from './styles';

interface Props {
  style?: React.CSSProperties | undefined;
}
const FormContainer: React.FC<Props> = ({ children, style }) => (
  <Container style={style}>{children}</Container>
);

export default FormContainer;
