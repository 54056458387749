import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-end;
  span {
    color: #000;
  }
`;
export const Button = styled.button`
  border: 0;
  background: ${colors.primary};
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
`;
export const Content = styled.div`
  flex-direction: row;
  display: flex;
`;
