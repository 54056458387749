import styled from 'styled-components';
import { colors } from '../../styles/constants';

interface ContainerProps {
  background: string;
}
export const Container = styled.div<ContainerProps>`
  flex-direction: row;
  height: 120px;
  min-width: 150px;
  flex: 1;
  margin: 5px;
  background: ${props => props.background};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.borders};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p {
    font-weight: bold;
    font-size: 32px;
    flex-wrap: wrap;
    text-align: end;
  }
  span {
    font-size: 14px;
  }
`;
