import styled from 'styled-components';
import { colors } from '../../styles/constants';

export const Container = styled.div`
  background: #fcfcfc;
  min-height: 80vh;
  padding: 50px 0px;
  button {
    min-width: 350px;
    max-width: 400px;
    margin: 5px 0px;
    height: 40px;
    border-radius: 5px;
    border-width: 0px;
    background: ${colors.backgroundSubmitButton};
    color: ${colors.textLight};
    font-size: 16px;
    font-weight: bold;
    @media screen and (max-width: 1200px) {
      margin: 5px 0px;
      min-width: 350px;
      max-width: 400px;
      height: 40px;
      border-radius: 5px;
      border-width: 0px;
      background: ${colors.backgroundSubmitButton};
      color: ${colors.textLight};
      font-size: 16px;
      font-weight: bold;
    }
    @media screen and (max-width: 800px) {
    }
    @media screen and (max-width: 600px) {
    }
    @media screen and (max-width: 450px) {
    }
  }
`;

export const ButtonCopy = styled.button`
  min-width: 30px !important;
  margin-right: auto !important;
  width: 30px !important;
`;
export const ContainerKey = styled.div`
  display: flex !important;
  min-width: 200px;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  span {
    flex: 1;
    margin-right: auto;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ContainerResume = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`;
