import React from 'react';

import { Container, Content } from './styles';

interface Props {
  background: string;
  children?: React.ReactNode;
  title?: string;
  label?: number | string;
}

const Card: React.FC<Props> = ({ background, children, title, label }) => (
  <Container background={background}>
    {children}
    <Content>
      <span>{title}</span>
      <p>{label}</p>
    </Content>
  </Container>
);
export default Card;
