import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Container } from './styles';
import { colors } from '../../styles/constants';

const ContainerLoading: React.FC = () => (
  <Container>
    <ClipLoader color={colors.primary} loading size={30} />
  </Container>
);
export default ContainerLoading;
