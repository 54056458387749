/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FcAndroidOs, FcCalendar } from 'react-icons/fc';
import { AiFillApple, AiOutlineIdcard } from 'react-icons/ai';
import { MdAddCircle } from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Table from '../../components/MaterialTable';
import { ILicense, PropsLicenseRow, StatsLicense } from '../../interfaces';
import { useAuth } from '../../hooks/auth';
import ContainerLoading from '../../components/ContainerLoading';
import { dateFormated } from '../../utils';
import { Container, CardContainer, ButtonNewLicense } from './styles';
import Card from '../../components/Card';
import { useRequest } from '../../hooks/api';
import TableColumns from './TableComponents/columns';
import { useToast } from '../../hooks/toast';

const Licenses: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const { api } = useRequest();
  const history = useHistory();
  const [stats, setStats] = useState<StatsLicense>();
  const [licenses, setLicenses] = useState<PropsLicenseRow[]>([]);

  const { addToast } = useToast();

  const toggleSent = useCallback(
    async key => {
      const toggleSentResponse = await api.patch(`/admin/licenses/${key}/sent`);
      if (toggleSentResponse.data) {
        const newLicenses = licenses.map(license => {
          if (license.keyId === key) {
            return {
              ...toggleSentResponse.data,
              keyId: toggleSentResponse.data.key,
              used: toggleSentResponse.data.used ? 'Used' : 'Unused',
              sent: toggleSentResponse.data.sent ? 'Sent' : 'Not Sent',
              type: toggleSentResponse.data.type,
              app: toggleSentResponse.data.is_ios ? 'iOS' : 'Android',
              client: {
                name:
                  toggleSentResponse.data?.licenseActivation?.device
                    .clientName || '--',
                id:
                  toggleSentResponse.data?.licenseActivation?.device.internalId,
              },
              activated: dateFormated(
                toggleSentResponse.data.licenseActivation?.createdAt,
              ),
              reseller: toggleSentResponse.data.reseller?.name ?? '--',
            };
          }
          return license;
        });

        setLicenses(newLicenses);
        addToast({
          title: `License ${key} marked as ${
            toggleSentResponse.data.sent ? 'Sent' : 'Not Sent'
          }`,
          type: 'success',
        });
      }
    },
    [addToast, api, licenses],
  );

  const columns = useMemo(() => {
    return TableColumns(licenses, toggleSent, user?.isAdmin ?? undefined);
  }, [licenses, toggleSent, user?.isAdmin]);

  useEffect(() => {
    async function loadLicenses() {
      const response = await api.get('/admin/licenses');
      const statsLicenses = await api.get('/admin/stats/licenses');
      if (statsLicenses) {
        setStats(statsLicenses?.data);
      }
      if (response.data) {
        setLicenses(
          response?.data.map((license: ILicense) => ({
            ...license,
            keyId: license.key,
            used: license.used ? 'Used' : 'Unused',
            sent: license.sent ? 'Sent' : 'Not Sent',
            type: license.type,
            app: license.is_ios ? 'iOS' : 'Android',
            client: {
              name: license?.licenseActivation?.device.clientName || '--',
              id: license?.licenseActivation?.device.internalId,
            },
            activated: dateFormated(license.licenseActivation?.createdAt),
            reseller: license.reseller?.name ?? '--',
          })),
        );
      }
      setLoading(false);
    }
    loadLicenses();
  }, [api]);
  return (
    <>
      {loading ? (
        <ContainerLoading />
      ) : (
        <Container>
          <CardContainer>
            <Card
              background="#fdfdfd"
              title="Android Licenses (unused)"
              label={stats?.paidNotUsed?.android as number}
            >
              <FcAndroidOs size={45} />
            </Card>

            <Card
              background="#fdfdfd"
              title="IOS Licenses (unused)"
              label={stats?.paidNotUsed?.ios as number}
            >
              <AiFillApple color="#000" size={45} />
            </Card>

            <Card
              background="#fdfdfd"
              title="Test Licenses (unused)"
              label={stats?.testsNotUsed?.total as number}
            >
              <AiOutlineIdcard size={45} />
            </Card>
            <Card
              background="#fdfdfd"
              title="Expiring in 24 hrs"
              label={stats?.expiringSoon?.total as number}
            >
              <FcCalendar size={45} />
            </Card>
          </CardContainer>
          <ButtonNewLicense onClick={() => history.push('/licenses/new')}>
            <MdAddCircle size={18} />
            <p>New license</p>
          </ButtonNewLicense>
          <Table
            title="Licenses"
            data={licenses}
            columns={columns}
            options={{
              filter: true,
              filterType: 'multiselect',
              fixedHeader: true,
              responsive: 'standard',
              selectableRows: 'none',
            }}
          />
        </Container>
      )}
    </>
  );
};

export default Licenses;
