import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ResellerComponent from '../../components/Reseller';
import { Container } from './styles';
import ContainerLoading from '../../components/ContainerLoading';
import { useRequest } from '../../hooks/api';

interface ResellerProps {
  id: number;
  name: string;
}
const Resellers: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [resellers, setResellers] = useState<ResellerProps[] | []>([]);
  const { api } = useRequest();
  const history = useHistory();
  useEffect(() => {
    async function loadResellers() {
      const response = await api.get('/admin/users');
      setResellers(response.data);
      setLoading(false);
    }
    loadResellers();
  }, []);
  return (
    <>
      {loading ? (
        <ContainerLoading />
      ) : (
        <Container>
          {resellers.map((reseller: ResellerProps) => (
            <ResellerComponent
              id={reseller.id}
              name={reseller.name}
              onClick={() =>
                history.push(`/resellers/${reseller.id}`, {
                  reseller: reseller.name,
                })
              }
            />
          ))}
        </Container>
      )}
    </>
  );
};

export default Resellers;
