import React from 'react';
import { Container } from './styles';

interface Props {
  background: string;
  color: string;
  label: string;
}
const TableCell: React.FC<Props> = ({ background, label, color }) => (
  <>
    <Container background={background} color={color}>
      <span>{label}</span>
    </Container>
  </>
);
export default TableCell;
