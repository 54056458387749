import React, { useRef, useCallback, useState, useEffect } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Container } from './styles';
import Input from '../../components/Form/Input';
import FormContainer from '../../components/Form/Container';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useRequest } from '../../hooks/api';

interface SignInFormData {
  password: string;
}

const PasswordCreate: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { signIn, user, signOut } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const { api } = useRequest();
  useEffect(() => {
    if (!user?.needPasswordUpdate) {
      history.goBack();
    }
  }, [history, user?.needPasswordUpdate]);
  const createPassword = useCallback(
    async (pass: string) => {
      await api.put('/admin/users', { password: pass });
      if (user) {
        await signIn({ email: user?.email, password: pass });
      }
    },
    [api, signIn, user],
  );
  const handleSubmit: SubmitHandler<SignInFormData> = useCallback(
    async ({ password }: SignInFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('Please type your password'),
        });
        await schema.validate(
          { password },
          {
            abortEarly: false,
          },
        );
        await createPassword(password);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Error',
          description: err.response.data.error,
        });
      }
    },
    [addToast, createPassword],
  );
  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormContainer>
          <Input
            name="password"
            placeholder="Type your password"
            type="password"
          />
          <button type="submit" style={{ background: '#000' }}>
            {loading ? (
              <ClipLoader color="#fff" size={10} />
            ) : (
              'Create Password'
            )}
          </button>
        </FormContainer>
      </Form>
      <button type="button" onClick={signOut} style={{ background: '#000' }}>
        Sign Out
      </button>
    </Container>
  );
};

export default PasswordCreate;
