import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FcAndroidOs, FcCalendar } from 'react-icons/fc';
import { AiFillApple } from 'react-icons/ai';
import { TiGroupOutline } from 'react-icons/ti';
import { isAfter, isBefore, addDays } from 'date-fns';
import { FaArrowRight } from 'react-icons/fa';
import { Container, CardContainer, Button } from './styles';
import Chart from '../../components/LineChart';
import { mapperChartData } from '../../utils';
import { ChartData, IClient } from '../../interfaces';
import DateRangeForm from '../../components/DateRangePicker';
import Card from '../../components/Card';
import { useRequest } from '../../hooks/api';

const Reseller: React.FC = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const [numberActive, setNumberActive] = useState<ChartData | null>();
  const [numberUser, setNumberUser] = useState<ChartData | null>();
  const [loadingNumberActive, setLoadingNumberActive] = useState(true);
  const [loadingNumberUser, setLoadingNumberUser] = useState(true);
  const [androidClients, setAndroidClients] = useState<number>(0);
  const [iosClients, setIosClients] = useState<number>(0);
  const [newClients, setNewClients] = useState<number>(0);
  const [expiringClients, setExpiringClients] = useState<number>(0);
  const { api } = useRequest();
  const history = useHistory();

  const loadNumberOfUsersActive = useCallback(
    async (start?: Date, end?: Date) => {
      setLoadingNumberActive(true);
      const response = await api.get(
        `/admin/graphs?stat=active_users&from=${start}&to=${end}&resellerId=${id}`,
      );
      if (response?.data) {
        const structuredData = mapperChartData(response.data, start, end);
        setNumberActive(structuredData as ChartData);
      }
      setLoadingNumberActive(false);
    },
    [api, id],
  );
  const loadNewUsers = useCallback(
    async (start?: Date, end?: Date) => {
      setLoadingNumberUser(true);
      const response = await api.get(
        `/admin/graphs?stat=new_users&from=${start}&to=${end}`,
      );
      if (response?.data) {
        setNumberUser({
          total: response.data.yAxis.total,
          android: response.data.yAxis.android,
          ios: response.data.yAxis.ios,
          xaxis: response.data.xAxis,
        });
      }
      setLoadingNumberUser(false);
    },
    [api],
  );

  const loadClients = useCallback(async () => {
    const statsResponse = await api.get(`admin/stats/devices?resellerId=${id}`);
    if (statsResponse) {
      setAndroidClients(
        (statsResponse?.data?.active?.android +
          statsResponse?.data?.activeTesting?.android) as number,
      );
      setIosClients(
        (statsResponse?.data?.active?.ios +
          statsResponse?.data?.activeTesting?.ios) as number,
      );
      setExpiringClients(statsResponse?.data?.expiringSoon?.total as number);
      setNewClients(statsResponse?.data?.newUsersWeek.total as number);
    }
  }, [api, id]);

  useEffect(() => {
    loadNumberOfUsersActive();
    loadClients();
    loadNewUsers();
  }, [loadClients, loadNewUsers, loadNumberOfUsersActive]);

  return (
    <Container>
      <CardContainer>
        <Button
          onClick={() =>
            history.push(`/clients`, {
              resellerFilter: history.location.state.reseller,
            })
          }
        >
          <TiGroupOutline size={25} />
          All Clients
          <FaArrowRight size={25} />
        </Button>
        <Card
          background="#fdfdfd"
          title="Android Clients (active and testing)"
          label={androidClients}
        >
          <FcAndroidOs size={35} />
        </Card>

        <Card
          background="#fdfdfd"
          title="iOS Clients (active and testing)"
          label={iosClients}
        >
          <AiFillApple color="#000" size={45} />
        </Card>

        <Card
          background="#fdfdfd"
          title="New Clients (week)"
          label={newClients}
        >
          <TiGroupOutline size={45} />
        </Card>
        <Card
          background="#fdfdfd"
          title="Expiring in 24 hrs"
          label={expiringClients}
        >
          <FcCalendar size={45} />
        </Card>
      </CardContainer>
      <Chart
        loading={loadingNumberActive}
        title="Number of Active Users"
        xaxis={numberActive?.xaxis as string[]}
        series={[
          {
            name: 'Android',
            data: numberActive?.android as number[],
          },
          {
            name: 'IOS',
            data: numberActive?.ios as number[],
          },
          {
            name: 'Total',
            data: numberActive?.total as number[],
          },
        ]}
      >
        <DateRangeForm
          onClick={(start, end) => loadNumberOfUsersActive(start, end)}
        />
      </Chart>
      <Chart
        loading={loadingNumberUser}
        title="New Users"
        xaxis={numberUser?.xaxis as string[]}
        series={[
          {
            name: 'Android',
            data: numberUser?.android as number[],
          },
          {
            name: 'IOS',
            data: numberUser?.ios as number[],
          },
          {
            name: 'Total',
            data: numberUser?.total as number[],
          },
        ]}
      >
        <DateRangeForm onClick={(start, end) => loadNewUsers(start, end)} />
      </Chart>
    </Container>
  );
};

export default Reseller;
