/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';

import Table from '../../components/MaterialTable';
import { StatsSales, ISales } from '../../interfaces';
import ContainerLoading from '../../components/ContainerLoading';
import { moneyFormat } from '../../utils';
import {
  Container,
  CardContainer,
  SalesCard,
  SalesCardValue,
  SalesCardTitle,
} from './styles';
import { useRequest } from '../../hooks/api';
import TableColumns from './TableComponents/columns';
import { useAuth } from '../../hooks/auth';

const Sales: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useRequest();
  const [stats, setStats] = useState<StatsSales>();
  const [sales, setSales] = useState<ISales[]>([]);

  const columns = useMemo(() => {
    return TableColumns(sales);
  }, [sales]);

  useEffect(() => {
    async function loadSales() {
      if (!user || !user.isAdmin) return;
      const response = await api.get('/admin/sales');
      const statResponse = await api.get('/admin/stats/revenue');
      if (statResponse) {
        setStats(statResponse.data);
      }
      if (response) {
        setSales(response.data);
      }
      setLoading(false);
    }
    loadSales();
  }, [api, user]);
  return (
    <>
      {loading ? (
        <ContainerLoading />
      ) : (
        <Container>
          <CardContainer>
            <SalesCard>
              <SalesCardTitle>Total (Since March 27th)</SalesCardTitle>
              <SalesCardValue>{moneyFormat(stats?.total)}</SalesCardValue>
            </SalesCard>
            <SalesCard>
              <SalesCardTitle>Last Week</SalesCardTitle>
              <SalesCardValue>{moneyFormat(stats?.lastWeek)}</SalesCardValue>
            </SalesCard>
            <SalesCard>
              <SalesCardTitle>Current Week</SalesCardTitle>
              <SalesCardValue>{moneyFormat(stats?.week)}</SalesCardValue>
            </SalesCard>
            <SalesCard>
              <SalesCardTitle>Day</SalesCardTitle>
              <SalesCardValue>{moneyFormat(stats?.day)}</SalesCardValue>
            </SalesCard>
          </CardContainer>
          <Table
            title="Sales"
            data={sales}
            columns={columns}
            options={{
              filter: true,
              enableNestedDataAccess: '.',
              filterType: 'multiselect',
              fixedHeader: true,
              responsive: 'standard',
              selectableRows: 'none',
            }}
          />
        </Container>
      )}
    </>
  );
};

export default Sales;
