import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ClipLoader } from 'react-spinners';
import { Container, Content } from './styles';
import { colors } from '../../styles/constants';

interface ChartProps {
  title: string;
  xaxis: string[];
  children?: React.ReactNode;
  loading: boolean;
  series: SeriesProps[];
}
interface SeriesProps {
  name: string;
  data: number[];
}
const LineChart: React.FC<ChartProps> = ({
  title,
  xaxis,
  children,
  loading,
  series,
}) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: title,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: xaxis,
      },
    });
  }, [title, xaxis]);

  return (
    <>
      <Container>
        {children}
        {loading ? (
          <Content>
            <ClipLoader color={colors.primary} loading size={30} />
          </Content>
        ) : (
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        )}
      </Container>
    </>
  );
};

export default LineChart;
