import React, { useState } from 'react';
import 'react-dates/initialize';
import { AiOutlineSearch } from 'react-icons/ai';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { subDays } from 'date-fns';
import { Container, Content, Button } from './styles';

interface Props {
  onClick: (start?: Date, end?: Date) => void;
}
const DateRangeForm: React.FC<Props> = ({ onClick }) => {
  const [value, onChange] = useState<[Date, Date]>([
    subDays(new Date(), 14),
    new Date(),
  ]);
  const find = () => {
    if (value) {
      onClick(value[0], value[1]);
    }
    onClick();
  };
  return (
    <Container>
      <Content>
        <DateRangePicker onChange={onChange} value={value} />
        <Button onClick={find}>
          <AiOutlineSearch size={25} color="#fff" />
        </Button>
      </Content>
    </Container>
  );
};
export default DateRangeForm;
