import styled from 'styled-components';

export const Container = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;
`;
export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  margin-right: 4px;
  p {
    font-size: 14px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
  }
`;
