import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    color: #000;
    font-size: 14px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  h1 {
    color: #000;
    font-size: 24px;
    margin-bottom: 10px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
  }
  span + span {
    margin-top: 16px;
  }
`;
export const CriteriasContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-top: 10px;
  padding: 10px;
  h1 {
    color: #000;
    font-size: 14px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;
export const StoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-top: 10px;
  padding: 10px;
  h1 {
    color: #000;
    font-size: 14px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;
export const CitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-top: 10px;
  padding: 10px;
  h1 {
    color: #000;
    font-size: 14px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;
export const BatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-top: 10px;
  padding: 10px;
  h1 {
    color: #000;
    font-size: 14px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;
export const LicensesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-top: 10px;
  padding: 10px;
  h1 {
    color: #000;
    font-size: 14px;
    font-size: 16px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;
`;

export const ContainerTitle = styled.div``;
export const InputContainer = styled.div`
  align-items: center;
  justify-content: space;
  display: flex;
`;
export const Input = styled.input`
  margin-right: 30px;
`;
