import Reactotron from 'reactotron-react-js';

declare global {
  interface Console {
    tron: any;
  }
}
if (process.env.NODE_ENV === 'development') {
  const hostname = 'localhost';

  const tron = Reactotron.configure({ host: hostname }).connect();

  tron.clear!();

  console.tron = tron;
} else {
  console.tron = {
    log: (message: string) => {
      console.log(message);
    },
  };
}
