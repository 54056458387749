import React, { useEffect, useMemo, useState } from 'react';
import { Container } from './styles';
import MaterialTable from '../../../components/MaterialTable';
import batchListColumns from './batchListColumns';
import firebaseConfig from '../../../config/firebaseConfig';
import ContainerLoading from '../../../components/ContainerLoading';

type Params = {
  udid?: string;
};
async function fetchData({
  lastFetchedPage,
  udid,
  page,
  data,
}: {
  udid: string;
  lastFetchedPage: number;
  page: number;
  data: any[];
}) {
  if (!udid) return [];
  const pageSize = 10;
  let response = firebaseConfig.db
    .collection('devices')
    .doc(udid)
    .collection('batchList')
    .orderBy('lastSeen', 'desc');
  if (
    data.length > 0 &&
    data[data.length - 1] !== null &&
    page > lastFetchedPage
  ) {
    console.log('NEXT PAGE');
    response = response.startAfter(data[data.length - 1]).limit(pageSize);
  } else if (page < lastFetchedPage && data.length > 0 && data[0] !== null) {
    response = response.endBefore(data[0]).limitToLast(pageSize);
    console.log('PREVIOUS PAGE');
  } else {
    response = response.limit(pageSize);
  }
  console.log('GET');
  const b = await response.get();

  return b.docs;
}
const ClientBatchList: React.FC<Params> = ({ udid }) => {
  const [batches, setBatches] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!udid) return;
    fetchData({
      udid,
      page: 0,
      lastFetchedPage,
      data,
    }).then(docs => {
      setData(docs);
      setLoading(false);
      setLastFetchedPage(0);
    });
  }, [udid]);

  useEffect(() => {
    setBatches(
      data.map(d => ({
        ...d.data().batch,
        id: d.id,
        lastSeen: d.data().lastSeen,
      })),
    );
  }, [data]);

  const columns = useMemo(() => {
    return batchListColumns(batches);
  }, [batches]);

  if (!udid) {
    return <ContainerLoading />;
  }
  return (
    <>
      <Container>
        <MaterialTable
          columns={columns}
          data={batches}
          title="Viewed Batches"
          options={{
            rowsPerPageOptions: [],
            search: false,
            filter: false,
            selectableRowsHeader: false,
            selectableRowsOnClick: false,
            isRowSelectable: () => false,
            fixedSelectColumn: false,
            sort: false,
            rowsPerPage: 10,
            serverSide: true,
            selectableRows: 'none',
            fixedHeader: true,
            responsive: 'standard',
            count: 10000,
            onRowClick: (rowData: string[], rowMeta) => {
              if (data[rowMeta.rowIndex].id) {
                const win = window.open(
                  `/clients/${udid}/batchlist/${data[rowMeta.rowIndex].id}`,
                  '_blank',
                );
                if (win) win.focus();
              }
            },
            onTableChange: (action, tableState) => {
              if (action === 'changePage') {
                fetchData({
                  udid,
                  page: tableState.page,
                  lastFetchedPage,
                  data,
                }).then(docs => {
                  setData(docs);
                  setLoading(false);
                  setLastFetchedPage(tableState.page);
                });
              }
            },
          }}
        />
      </Container>
    </>
  );
};

export default React.memo(ClientBatchList);
