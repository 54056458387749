import React, { useState } from 'react';

import clsx from 'clsx';
import {
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MainListItems from './Sidebar';
import { colors } from '../../styles/constants';
import {
  useStyles,
  MenuLeft,
  MenuRight,
  MenuProfile,
  MenuItemProfile,
  MenuTitle,
  MenuSubTitle,
  Logo,
} from './styles';
import { useAuth } from '../../hooks/auth';
import logo from '../../assets/logotest.png';

const Navbar: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { user, signOut } = useAuth();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openMenuTest, setOpenMenuTest] = useState(false);
  const anchorRef = React.useRef(null);

  const profile = {
    name: user?.name,
  };

  const handleToggle = () => {
    setOpenMenuTest(!openMenuTest);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ backgroundColor: colors.primary }}
      >
        <Toolbar className={classes.toolbar}>
          <MenuLeft>
            {open ? null : (
              <>
                <Logo src={logo} />
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setOpen(!open)}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
          </MenuLeft>
          <MenuRight>
            <MenuProfile
              ref={anchorRef}
              aria-controls={openMenuTest ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MenuItemProfile>
                <MenuTitle>{profile.name}</MenuTitle>
                <MenuSubTitle>Sign Out</MenuSubTitle>
              </MenuItemProfile>
            </MenuProfile>

            <Popper
              open={openMenuTest}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => setOpenMenuTest(false)}
                    >
                      <MenuList
                        autoFocusItem={openMenuTest}
                        id="menu-list-grow"
                      >
                        <MenuItem onClick={signOut}>Sign Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </MenuRight>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <p style={{ fontWeight: 'bold', fontSize: 22 }}>MIB</p>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems />
      </Drawer>
    </>
  );
};

export default Navbar;
