import styled from 'styled-components';
import { colors } from '../../../styles/constants';

export const Container = styled.div`
  min-width: 350px;
  max-width: 400px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px 0px;

  @media screen and (max-width: 1200px) {
    min-width: 350px;
    max-width: 400px;
  }
  h1 {
    font-size: 14px;
  }
`;
export const Content = styled.div`
  display: flex;
  min-width: 350px;
  max-width: 400px;
  margin: 2px 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.borders};
  padding: 10px 0px;
  input {
    margin: 0px 10px;
  }
  @media screen and (max-width: 1200px) {
    min-width: 350px;
    max-width: 400px;
  }
`;
export const Error = styled.span`
  color: ${colors.error};
  font-size: 14px;
  align-self: flex-start;
  margin: 0px 0px 4px 0px;
`;
