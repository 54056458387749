import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const drawerWidth = 240;

export const Logo = styled.img`
  height: 50px;
  margin: 10px;
`;

export const MenuLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const MenuRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MenuProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: background 0.2s;
  padding: 8px;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;
export const MenuItemProfile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: right;
  margin: 0px 5px 0px 5px;
`;
export const MenuTitle = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 10px 0px 0px;
`;
export const MenuSubTitle = styled.span`
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  margin: 0px 10px 0px 0px;
  text-transform: uppercase;
`;

export const Avatar = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
    paddingLeft: 0, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    minHeight: '100vh',
    height: '100%',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  fixedHeight: {
    height: 240,
  },
  iconRight: {
    marginRight: 10,
    position: 'absolute',
    right: 0,
    top: 10,
  },
}));
