import { useCallback, useEffect, useRef, useState } from 'react';
import firebaseConfig from '../../config/firebaseConfig';
import { useAuth } from '../../hooks/auth';
import MapComponent from '../../components/MapComponent';
import { Button, Switch } from '@material-ui/core';

function ClientsMapPage() {
  const props = {
    center: {lat: 37.8097343, lng:-98.5556199},
    zoom: 5
  };
  const { user } = useAuth();
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    onlyBotRunning: false,
    onlyNotExpired: false,
  })
  useEffect(() => {
    if(!user || !user.isAdmin) return;
    let response = firebaseConfig.db.collection('devices')
    if(options.onlyBotRunning) {
      response = response.where('active', '==', true);
    }
    if(options.onlyNotExpired) {
      response = response.where('expirationDate', '>', new Date());
    }
    if(!options.onlyNotExpired && !options.onlyBotRunning) {
      console.log('both false');
      response = response.orderBy("lastUpdatedTime", "desc");
    }
    updatePoints(response);
  }, [user, options]);

  const updatePoints = useCallback((response) => {
    response.get().then(snapshot => {
      const p = snapshot.docs.filter(item => item.data().lastStartedLocation && item.data().lastStartedLocation.coords)
      .map(item=> {
        const data = item.data();
        return {
        type: "Feature",
        properties: { cluster: false, id: data.internalId },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(data.lastStartedLocation.coords.longitude),
            parseFloat(data.lastStartedLocation.coords.latitude)
          ]
        },
        onPointClick: () => {
          const win = window.open(`/clients/${data.internalId}`, '_blank');
          if (win) win.focus();
        },
        pointTooltip: (<div><p>{data.internalId}</p></div>),
      }}
    );
    setPoints(p);
    setLoading(false);
    });
  });
  return (
  <div style={{ height: '80vh', width: '100%', flex: 1}}>
    <h1> Clients Map Page </h1>
      <h2 style={{marginBottom: '20px'}}>Total: {points.length}</h2>
      <div style={{flex:1, display:'flex', flexDirection: 'row'}}>
        <div style={{marginRight: '20px'}}>
          <h3>Only Active</h3>
          <Switch color="primary" value={options.onlyNotExpired} onChange={(ev)=> {
            console.log(ev.target.checked);
            setOptions({...options, onlyNotExpired: ev.target.checked})}}/>
        </div>
        <div>
          <h3>Only Running</h3>
          <Switch color="primary" value={options.onlyBotRunning} onChange={(ev)=> setOptions({...options, onlyBotRunning: event.target.checked})}/>
        </div>
      </div>
    <br/>
    {!loading && points.length > 0 && (<MapComponent mapProps={props} points={points}/>)}
  </div>);
}
export default ClientsMapPage;
