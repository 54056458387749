import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.2);
  margin-bottom: 10px;
  padding: 32px;
  h1 {
    color: #000;
    font-size: 32px;
  }
  h2 {
    color: #000;
    font-size: 24px;
  }
  span {
    flex-direction: row;
    font-size: 16px;
    font-weight: 300;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #000;
  font-size: 18px;
  h2 {
    color: #000;
    font-size: 24px;
    margin-right: 8px;
  }
  @media (max-width: 768px) {
    color: #000;
    font-size: 12px;
    h2 {
      color: #000;
      font-size: 14px;
      margin-right: 3px;
    }
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
