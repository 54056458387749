import styled from 'styled-components';

interface Props {
  background: string;
  color: string;
}
export const Container = styled.div<Props>`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 2px;
  background: ${props => props.background};
  color: ${props => props.color};
  span {
    font-weight: bold;
  }
`;
